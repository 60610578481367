import {Grid} from "@mui/material";
import {InnerWidthContainer} from "components/widthControlElements";
import {getPlayerScoresAppearances} from "firestore/specificGetters/StatisticsFirestore";
import {StatisticsPlayerShooterAppearanceFirestoreDocument} from "models";
import React, {useEffect, useState} from "react";
import {DesktopPlayerStatisticsTable} from "views/club/statistics/desktop/player/DesktopPlayerStatisticsTable";
import {useSeasonSettings} from "@nitra/nkmb-card-components";

export function DesktopPlayerStatistics() {
	const [scorers, setScorers] = useState<StatisticsPlayerShooterAppearanceFirestoreDocument[]>([])
	const [appearances, setAppearances] = useState<StatisticsPlayerShooterAppearanceFirestoreDocument[]>([])
	const {
		setAllowAllSeasons,
		setFirstSeasonOverride,
		setLastSeasonOverride,
		setAvailableSeasonsOverride
	} = useSeasonSettings()

	useEffect(() => {
		setAllowAllSeasons(true)
		setAvailableSeasonsOverride(undefined);
		setFirstSeasonOverride(undefined);
		setLastSeasonOverride(undefined);
		get()
	}, []);

	async function get() {
		getPlayerScoresAppearances().then((payload) => {
			setScorers(payload.scorers)
			setAppearances(payload.appearances)
		})
	}

	return (
		<InnerWidthContainer>
			<Grid container spacing={3} sx={{mt: 5}}>
				<Grid item xs={12} lg={6}>
					<DesktopPlayerStatisticsTable title={"Naj strelci"} players={scorers}/>
				</Grid>
				<Grid item xs={12} lg={6}>
					<DesktopPlayerStatisticsTable title={"Naj nastopi"} players={appearances}/>
				</Grid>
			</Grid>
		</InnerWidthContainer>
	)

}

