import Banner from "../../components/Banner";
import BannerFirebaseDocument from "../../models/firestore/common/BannerFirestoreDto";

export function LandingTopBanner(props: { data: BannerFirebaseDocument }) {
	const {title, imageUrl, createdAt, actionLink, isForMatch} = props.data;

	return (
		<Banner
			title={title}
			imageUrl={imageUrl}
			createdAt={createdAt}
			onClickLink={actionLink}
			isForMatch={isForMatch}
			desktopHeight={480}
		/>
	)
}
