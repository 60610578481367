import React, {useEffect, useState} from "react";
import {Button, Typography} from "@mui/material";
import {ChevronLeft} from "@mui/icons-material";
import {useCompetitionViewContext} from "../common/CompetitionContext";
import useCompetitionFilterButtonsContext
	from "../../../components/CompetitionFilterButtons/CompetitionFilterButtonsContext";

const JumpToNextMatchButton = () => {
	const [scrolled, setScrolled] = useState(false);

	const {
		setCompetitionsFilter,
		firstRef,
		currentRef,
		scrollContainerRef,
		scrollTrigger
	} = useCompetitionViewContext();

	const {filteredCompetitions} = useCompetitionFilterButtonsContext()

	const handleScroll = () => {
		setScrolled((scrollContainerRef.current?.scrollTop ?? 0) > 0)
	}

	useEffect(() => {
		handleScroll();
		if (scrollContainerRef.current) {
			scrollContainerRef.current.addEventListener("scroll", handleScroll);
			return () => window.removeEventListener("scroll", handleScroll);
		}
	}, [scrollContainerRef]);

	useEffect(() => {
		setCompetitionsFilter(filteredCompetitions)
	}, [filteredCompetitions]);

	useEffect(() => {
		handleQuickButton()
	}, [scrollTrigger]);

	const handleQuickButton = () => {
		const scrollTargetRef = scrolled ? firstRef : currentRef;
		if (scrollTargetRef.current && scrollContainerRef.current) {
			const targetPosition = scrollTargetRef.current.offsetTop;
			const containerPosition = scrollContainerRef.current.offsetTop;
			const scrollDistance = targetPosition - containerPosition;

			scrollContainerRef.current.scrollTo({top: scrolled ? 0 : scrollDistance, behavior: 'smooth'});
		}
	}

	useEffect(() => {
		if (!scrolled) handleQuickButton()
	}, []);

	return (
		<Button onClick={handleQuickButton}
		        sx={{
			        backgroundColor: "background.default",
			        ml: "auto",
			        borderRadius: 24,
			        px: 2
		        }}>
			<Typography variant={"gumbS"}>{scrolled ? "Na vrh" : "Na naslednjo tekmo"}</Typography>
			{scrolled ? <ChevronLeft sx={{transform: "rotate(90deg)"}}/> :
				<ChevronLeft sx={{transform: "rotate(270deg)"}}/>}
		</Button>

	)
}

export default JumpToNextMatchButton
