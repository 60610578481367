import useCompetitionStatisticsContext from "../common/CompetitionStatisticsContext";
import React, {useEffect} from "react";
import {alpha, Box, Stack, SvgIcon, Typography, useTheme} from "@mui/material";
import {ECompetitionType, useIsMobileContext} from "@nitra/nkmb-card-components";
import {DataGrid, GridColumns} from "@mui/x-data-grid";
import {CompetitionStatisticsPlayerFirestoreDocument, EPlayerPositionToPretty} from "../../../models";
import typography from "@nitra/nkmb-theme/typography";
import {NkmbIconKartonpoln} from "@nitra/nkmb-theme";

/**
 * Calculates the age of the player at either a specific date in time, or today.
 * If seasonNumber is either 42 or the current year or last year,
 * but it's still before end of May, then it should be ignored.
 *
 * Otherwise, the calculation should be done against the 30th May of the seasonNumber+1 year.
 * @param dateOfBirth
 * @param seasonNumber
 */
function calculateAge(dateOfBirth: Date, seasonNumber: number): number {
	const currentYear = new Date().getFullYear()
	const currentMonth = new Date().getMonth()
	if (seasonNumber === 42 || seasonNumber === currentYear || seasonNumber === currentYear - 1) {
		if (currentMonth < 5) {
			return currentYear - dateOfBirth.getFullYear() - 1
		} else {
			return currentYear - dateOfBirth.getFullYear()
		}
	} else {
		return seasonNumber + 1 - dateOfBirth.getFullYear()
	}
}

export function CompetitionStatisticsClubPlayersTable() {
	const {playersData, seasonNumber} = useCompetitionStatisticsContext();

	const [borderRadius, setBorderRadius] = React.useState("8px")

	const theme = useTheme()
	const isMobile = useIsMobileContext();

	useEffect(() => {
		setBorderRadius(isMobile ? "0px" : "8px")
	}, [isMobile]);

	const columns: GridColumns<CompetitionStatisticsPlayerFirestoreDocument> = [
			{
				field: "jerseyNumber",
				headerName: isMobile ? "#" : "Dres",
				headerAlign: "center",
				align: "center",
				width: isMobile ? 30 : 50,
				maxWidth: isMobile ? 30 : 50,
				minWidth: isMobile ? 30 : 50,
				renderHeader: () =>
					<Typography variant={"bodySBold"}
					            sx={{borderTopLeftRadius: borderRadius}}>{isMobile ? "#" : "Dres"}</Typography>
				,
				renderCell: (params: { row: CompetitionStatisticsPlayerFirestoreDocument }) =>
					<Typography
						sx={{color: "#676767"}}
						variant={isMobile ? "bodyM" : "podnaslovM"}>{params.row.jerseyNumber}</Typography>
			},
			{
				field: "name",
				headerName: "Ime Priimek",
				headerAlign: "left",
				align: "left",
				flex: 1,
				valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`,
				renderHeader: () => <Typography sx={{pl: isMobile ? "8px" : "unset"}} variant={"bodySBold"}>Ime
					Priimek</Typography>,
				renderCell: (params: { row: CompetitionStatisticsPlayerFirestoreDocument }) =>
					<Stack direction={"row"} sx={{alignItems: "center", minHeight: "40.5px"}}>
						{!isMobile &&
							(params.row.face42x42url ?
								<img src={params.row.face42x42url}
								     alt={params.row.firstName + " " + params.row.lastName}
								     style={{"borderRadius": "40px", minWidth: "40px", height: "40px", width: "40px"}}/>
								:
								<Box sx={{
									width: "40px",
									height: "40px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}>
									<img src={params.row.face42x42url ?? "/images/mask_group.png"}
									     alt={params.row.firstName + " " + params.row.lastName}
									     style={{
										     "borderRadius": "32px",
										     minWidth: "32px",
										     height: "32px",
										     width: "32px"
									     }}/>
								</Box>)
						}
						<Typography
							sx={{pl: 1, color: isMobile ? theme.palette.text.primary : "unset", textWrap: "wrap"}}
							variant={isMobile ? "bodyMBold" : "bodyLBold"}>{params.row.firstName} {params.row.lastName}
						</Typography>
					</Stack>
			},

		]
	;

	if (!isMobile) {
		columns.push({
			field: "position",
			headerName: "Igralno mesto",
			renderHeader: () => <Typography sx={{width: "74px"}} variant={"bodySBold"}>Igralno
				mesto</Typography>,
			renderCell: (params: { row: CompetitionStatisticsPlayerFirestoreDocument }) =>
				<Typography variant={"bodyL"}>{EPlayerPositionToPretty(params.row.position)}</Typography>,
		})
	}

	columns.push({
		field: "goals",
		headerName: "Zadetki",
		headerAlign: "center",
		width: isMobile ? 20 : 59,
		minWidth: isMobile ? 30 : 59,
		maxWidth: isMobile ? 30 : 59,
		align: "center",
		renderHeader: () => <Typography variant={"bodySBold"}>{isMobile ? "Z" : "Zadetki"}</Typography>,
		renderCell: (params: { row: CompetitionStatisticsPlayerFirestoreDocument }) => <Typography
			variant={isMobile ? "bodySBold" : "bodyM"}>{params.row.competitionStats.reduce((acc, curr) => acc + curr.goals, 0)}</Typography>,
		valueGetter: (params) => params.row.competitionStats.reduce((acc, curr) => acc + curr.goals, 0)
	})

	if (seasonNumber >= 2024) {
		columns.push({
			field: "assists",
			headerName: "Asistence",
			headerAlign: "center",
			align: "center",
			width: isMobile ? 20 : 70,
			minWidth: isMobile ? 30 : 70,
			maxWidth: isMobile ? 30 : 70,
			renderHeader: () => <Typography
				variant={"bodySBold"}>{isMobile ? "A" : "Asistence"}</Typography>
			,
			renderCell: (params: { row: CompetitionStatisticsPlayerFirestoreDocument }) => <Typography
				variant={isMobile ? "bodySBold" : "bodyM"}>{params.row.competitionStats.reduce((acc, curr) => acc + curr.assists, 0)}</Typography>,
			valueGetter: (params) => params.row.competitionStats.reduce((acc, curr) => acc + curr.assists, 0)
		})
	}

	if (isMobile) {
		columns.push({
			field: "yellowCards",
			headerName: "Rumeni kartoni",
			headerAlign: "center",
			align: "center",
			width: 20,
			minWidth: 35,
			maxWidth: 35,
			renderHeader: () =>
				<SvgIcon component={NkmbIconKartonpoln}
				         sx={{width: 18, height: 18, color: "#FECE00", pt: "6px", pb: "6px"}}
				         viewBox={"0 0 100 100"}/>
			,
			renderCell: (params: { row: CompetitionStatisticsPlayerFirestoreDocument }) => <Typography
				variant={isMobile ? "bodySBold" : "bodyM"}>{params.row.competitionStats.reduce((acc, curr) => acc + curr.yellowCards, 0)}</Typography>,
			valueGetter: (params) => params.row.competitionStats.reduce((acc, curr) => acc + curr.yellowCards, 0)
		})
	} else {
		columns.push({
			field: "yellowCards",
			headerName: "Rumeni kartoni",
			width: 80,
			align: "center",
			renderHeader: () =>
				<Stack sx={{alignItems: "center", paddingTop: "12px", paddingBottom: 0, width: "60px"}}>
					<Typography variant={"bodySBold"}>Rumeni k. </Typography>
					<Typography variant={"podnapis"}>zadnji karton </Typography>
				</Stack>
			,
			renderCell: (params: { row: CompetitionStatisticsPlayerFirestoreDocument }) => {
				const lastYellowCardRound =
					params.row.competitionStats.find((stat) => stat.competitionType === ECompetitionType.FIRST_LEAGUE)?.roundOfLastYellowCard
				const yellowCardCount = params.row.competitionStats.reduce((acc, curr) => acc + curr.yellowCards, 0)

				if (!!lastYellowCardRound && yellowCardCount != 0) {
					return (
						<Stack alignItems={"center"} sx={{paddingTop: "12px", paddingBottom: 0}}>
							<Typography variant={isMobile ? "bodySBold" : "bodyM"}>{yellowCardCount}</Typography>
							<Typography
								variant={isMobile ? "bodySBold" : "podnapis"}>{lastYellowCardRound}.
								krog</Typography>
						</Stack>
					)
				} else {
					return (
						<Typography variant={isMobile ? "bodySBold" : "bodyM"}>{yellowCardCount}</Typography>
					)
				}
			},
			valueGetter: (params) => params.row.competitionStats.reduce((acc, curr) => acc + curr.yellowCards, 0)
		})
	}
	if (isMobile) {
		columns.push({
			field: "redCards",
			headerName: "Rdeči kartoni",
			headerAlign: "center",
			align: "center",
			width: 20,
			minWidth: 35,
			maxWidth: 35,
			renderHeader: () =>
				<SvgIcon component={NkmbIconKartonpoln}
				         sx={{width: 18, height: 18, color: "#C20831", pt: "6px", pb: "6px"}}
				         viewBox={"0 0 100 100"}/>
			,
			renderCell: (params: { row: CompetitionStatisticsPlayerFirestoreDocument }) => <Typography
				variant={isMobile ? "bodySBold" : "bodyM"}>{params.row.competitionStats.reduce((acc, curr) => acc + curr.redCards, 0)}</Typography>,
			valueGetter: (params) => params.row.competitionStats.reduce((acc, curr) => acc + curr.redCards, 0)
		})
	} else {
		columns.push({
			field: "redCards",
			headerName: "Rdeči kartoni",
			headerAlign: "center",
			align: "center",
			width: 60,
			renderHeader: () => <Typography variant={"bodySBold"}>Rdeči k.</Typography>,
			renderCell: (params: { row: CompetitionStatisticsPlayerFirestoreDocument }) => <Typography
				variant={isMobile ? "bodySBold" : "bodyM"}>{params.row.competitionStats.reduce((acc, curr) => acc + curr.redCards, 0)}</Typography>,
			valueGetter: (params) => params.row.competitionStats.reduce((acc, curr) => acc + curr.redCards, 0)
		})
	}
	columns.push({
		field: "minutes",
		headerName: "Minute",
		headerAlign: "center",
		align: "center",
		width: isMobile ? 20 : 60,
		minWidth: isMobile ? 30 : 60,
		maxWidth: isMobile ? 30 : 60,
		renderHeader: () => <Typography variant={"bodySBold"}>{isMobile ? "M" : "Minute"}</Typography>,
		renderCell: (params: { row: CompetitionStatisticsPlayerFirestoreDocument }) => <Typography
			variant={isMobile ? "bodySBold" : "bodyM"}>{params.row.competitionStats.reduce((acc, curr) => acc + curr.minutes, 0)}</Typography>,
		valueGetter: (params) => params.row.competitionStats.reduce((acc, curr) => acc + curr.minutes, 0)
	})

	if (!isMobile) {
		columns.push({
			field: "appearances",
			headerName: "Nastopi",
			headerAlign: "center",
			align: "center",
			width: 60,
			renderHeader: () => <Typography variant={"bodySBold"}>Nastopi</Typography>,
			renderCell: (params: { row: CompetitionStatisticsPlayerFirestoreDocument }) => <Typography
				variant={isMobile ? "bodySBold" : "bodyM"}>{params.row.competitionStats.reduce((acc, curr) => acc + curr.matches, 0)}</Typography>,
			valueGetter: (params) => params.row.competitionStats.reduce((acc, curr) => acc + curr.matches, 0)
		})

		columns.push({
			field: "age",
			headerName: "Starost",
			headerAlign: "center",
			align: "center",
			width: 60,
			renderHeader: () =>
				<Typography sx={{borderTopRightRadius: "16px"}} variant={"bodySBold"}>Starost</Typography>,
			renderCell: (params: { row: CompetitionStatisticsPlayerFirestoreDocument }) =>
				<Typography variant={isMobile ? "bodySBold" : "bodyM"}>
					{calculateAge(params.row.dateOfBirth, seasonNumber)}
				</Typography>
		})
	}

	return (
		<DataGrid
			columns={columns}
			rows={playersData}
			getRowId={(row) => row.key}
			hideFooterPagination={true}
			hideFooter={true}
			autoHeight={true}
			disableColumnMenu={true}
			sx={{
				'& .MuiDataGrid-columnSeparator': {
					display: "none"
				},
				'& .MuiDataGrid-columnHeaders': {
					backgroundColor: isMobile ? alpha(theme.palette.text.primary, 0.07) : theme.palette.background.paper,
					borderTopRadius: borderRadius,
					'& .MuiTableCell-root': {
						py: isMobile ? "2px" : 2,
					},
					color: isMobile ? alpha(theme.palette.text.primary, 0.3) : "#676767",
				},
				'& .MuiDataGrid-columnHeaderTitle': {
					...typography.bodySBold,
					color: "#676767",
				},
				backgroundColor: theme.palette.background.default,
				borderRadius: borderRadius,
				boxShadow: isMobile ? "" : "0px 0px 8px 0px #0000001F",
				'& .MuiTableCell-root': {
					py: isMobile ? 0 : 1,
					px: 1,
				},
				mt: isMobile ? 2 : 4,
				maxWidth: "912px",
			}}
			rowHeight={isMobile ? 40 : 50}
			headerHeight={isMobile ? 30 : 50}

		/>
	)
}