import React from "react";
import {useTheme} from "@mui/material";
import {BigCalendarDesktop} from "./BigCalendarDesktop";
import {YearOverviewCalendar} from "./YearOverviewCalendar";
import {BigCalendarMobile} from "./BigCalendarMobile";
import {getSeasonMatches} from "../../../firestore/BaseFirestore";
import {TimeManagementContextProvider, useIsMobileContext} from "@nitra/nkmb-card-components";
import {InnerWidthContainer} from "../../../components";

/**
 * Calendar view for all matches
 * @constructor
 */
export function CompetitionCalendar() {
	const isMobile = useIsMobileContext()
	const theme = useTheme()

	return (
		<TimeManagementContextProvider getterFunction={getSeasonMatches}>
			{isMobile ? <BigCalendarMobile/> : <BigCalendarDesktop/>}
			<InnerWidthContainer sx={{pt: 10}}>
				<YearOverviewCalendar/>
			</InnerWidthContainer>
		</TimeManagementContextProvider>
	)
}


