import {
	SeasonTrophiesFirestoreDocument,
	SeasonTrophiesListContainerFirestoreDocument
} from "@nitra/nkmb-card-components";
import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "../BaseFirestore";
import {mapAndSortContent} from "../mappers/ContentMapper";

export async function getSeasonTrophiesList(): Promise<SeasonTrophiesListContainerFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, 'seasonTrophies/'), "list"));

	if (!snapshot.exists()) throw new Error('Season trophies list not found');

	return {
		nzs: snapshot.data().nzs,
		uefa: snapshot.data().uefa,
		mobile: snapshot.data().mobile,

		leagueWinnerCount: snapshot.data().leagueWinnerCount,
		cupWinnerCount: snapshot.data().cupWinnerCount,
		superCupWinnerCount: snapshot.data().superCupWinnerCount,
	} as SeasonTrophiesListContainerFirestoreDocument;
}

export async function getSeasonTrophiesText(competition: string, key: string): Promise<SeasonTrophiesFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, `seasonTrophies/${competition}/seasonTrophies/`), key));

	if (!snapshot.exists()) throw new Error('Season trophies not found');

	return {
		key: snapshot.data().key,
		createdAt:  snapshot.data().createdAt.toDate(),
		updatedAt: snapshot.data().updatedAt.toDate(),
		season: snapshot.data().season,
		title: snapshot.data().title,
		subtitle: snapshot.data().subtitle,
		image1440x680url: snapshot.data().image1440x680url,
		content: mapAndSortContent(snapshot.data().content),
	} as SeasonTrophiesFirestoreDocument;
}