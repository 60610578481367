import {ContentContainer} from "components/ContentRender/ContentContainer";
import {getNewsArticle} from "firestore/BaseFirestore";
import {ArticleMatchMetaDataFirestoreDocument, ContentElementPageCacheFirebaseDocumentUnion} from "models";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {NewsArticleFooter} from "./NewsArticleFooter";
import {NewsArticleHeader} from "./NewsArticleHeader";
import {ArticleListFirestoreDocument, useIsMobileContext} from "@nitra/nkmb-card-components";
import {ArticleMatchStrip} from "./matchStrip/ArticleMatchStrip";
import {Box} from "@mui/material";


const NewsArticle = () => {
	const {id} = useParams();
	const isMobile = useIsMobileContext();

	const [content, setContent] = useState<
		ContentElementPageCacheFirebaseDocumentUnion[]
	>([]);
	const [title, setTitle] = useState("");
	const [createdAt, setCreatedAt] = useState<Date>(new Date());
	const [imageUrl, setImageUrl] = useState("");
	const [suggestedArticles, setSuggestedArticles] = useState<ArticleListFirestoreDocument[]>([]);
	const [nextArticle, setNextArticle] = useState<ArticleListFirestoreDocument | undefined>(undefined);
	const [matchMetaData, setMatchMetaData] = useState<ArticleMatchMetaDataFirestoreDocument | undefined>(undefined);

	useEffect(() => {
		if (!id) {
			console.error("No id provided");
			return;
		}
		getNewsArticle(id).then((article) => {
			setContent(article.content);
			setTitle(article.title);
			setCreatedAt(article.createdAt);
			if (article.image1440x680url) setImageUrl(article.image1440x680url);
			setSuggestedArticles(article.suggestedArticles);
			setNextArticle(article.nextArticle)
			setMatchMetaData(article.matchMetaData);
		}, (error) => {
			console.error(error);
		})
	}, [id])


	return (
		<Box sx={{position: "relative", width: "100%", maxWidth: "1440px"}}>
			{!!matchMetaData &&
                <ArticleMatchStrip sx={isMobile ? {} : {
					position: "absolute", right: 0, top: 0, zIndex: 1,
				}} title={title} imageUrl={imageUrl} matchMetaData={matchMetaData}/>
			}
			<Box sx={(!isMobile && !!matchMetaData) ? {maxWidth: "calc(100% - 348px)"} : {}}>
				{((isMobile && !matchMetaData) || (!isMobile)) &&
                    <NewsArticleHeader title={title} imageUrl={imageUrl} createdAt={createdAt}/>}
				<ContentContainer content={content}/>
				<NewsArticleFooter suggestedArticles={suggestedArticles} nextArticle={nextArticle}/>
			</Box>
		</Box>
	);
}

export default NewsArticle;

