import {Box, Grid, Stack, Typography, useTheme} from "@mui/material";

import Banner from "../../components/Banner";
import {INNER_MAX_WIDTH, INNER_PADDING} from "../../App";
import {VipTexts} from "./VipTexts";
import {BasicVipElement} from "./BasicVipElement";
import {VipButton} from "./VipButton";
import {VipPhotoGallery} from "./VipPhotoGallery";
import React from "react";
import {Todo} from "../../components/Todo";
import {DoubleVideoCard, ShareArea} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

function VipVps() {
	const theme = useTheme();

	const {isIos} = useIosContext()

	return (
		<Stack
			alignItems={"center"}
			direction={"column"}
			sx={{
				display: "flex",
				flexGrow: 1,
				width: "100%"
			}}>
			<Banner
				title={VipTexts.vps.banner.title}
				subtitle={VipTexts.vps.banner.subtitle}
				imageUrl={"/images/hospitality/vps/vps-sedezi.png"}
				desktopHeight={570}
			/>
			<Grid
				sx={{
					width: "100%",
					maxWidth: INNER_MAX_WIDTH,
					px: INNER_PADDING,
					mx: "auto",
					pt: {xs: 2, md: 4},
					flexGrow: 1,
					mt: 0,
					color: theme.palette.background.paper,
				}}
				container
				spacing={2}
			>
				<Stack
					sx={{
						flexDirection: {
							md: "column",
							lg: "row"
						},
						width: "100%"
					}}>
					<Box
						sx={{
							maxWidth: "600px",
							width: "100%",
							mr: 8,
							mb: 4
						}}>
						<DoubleVideoCard youTubeId={"3JeVWd-fAbc"}/>
					</Box>
					<Stack spacing={0} sx={{maxWidth: "600px"}}>
						<Typography variant={"h3"} sx={{
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
							pb: 2,
						}}>{VipTexts.vps.title1}</Typography>
						{VipTexts.vps.body1.split("⦁").map((line, index) => line.trim() &&
                            <Typography key={index} variant={"body1"} sx={{mt: 0}}>⦁ {line}</Typography>
						)}
					</Stack>
				</Stack>
				<Grid item xs={12} sx={{mt: 4}}>
					<Typography variant={"h3"} sx={{
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}>{VipTexts.vps.title2}</Typography>
					{VipTexts.vps.body2.split("⦁").map((line, index) => line.trim() &&
                        <Typography key={index} sx={{mt: 2}} variant={"body1"}>{line}</Typography>
					)}
				</Grid>
				<Todo label={"Tu se moremo dogovorit kateri vse gumbi so sploh smiselni"}/>
				<Grid item sx={{mt: 4}} container>
					<VipButton
						label={"Izbira sedeža"}
						href={"https://www.vijolcnabajta.si/si/sezonska-vstopnica-2024-2025-vijol-cni-premium-sedez/paketi-110924.html"}
					/>
					<ShareArea sx={{ml: 2}} shareUrl={"/"} brightText={true}/>
					<VipButton
						label={"hospitality@nkmaribor.com"}
						href={"mailto:hospitality@nkmaribor.com"}
						sx={{ml: "auto"}}/>
				</Grid>
				<VipPhotoGallery imageUrls={VipTexts.vps.imageUrls}/>
				<Box sx={{
					position: "relative",
					mt: 4,
					py: 4,
				}}>
					<Box sx={{
						position: "absolute",
						top: 0,
						left: "-50vw",
						right: "-50vw",
						height: "100%",
						backgroundColor: "rgba(217, 217, 217, 0.1)",
					}}/>
					<BasicVipElement {...VipTexts.basic.skybox}/>
				</Box>
			</Grid>
		</Stack>
	)
}

export default VipVps;
