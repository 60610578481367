import {useIsMobileContext} from "@nitra/nkmb-card-components";
import usePlayerStatsContext from "./PlayerStatsContext";
import {Box, Stack} from "@mui/material";
import {PlayerStatsSingleAttributeElement} from "./PlayerStatsSingleAttributeElement";
import {
	NkmbIconGol,
	NkmbIconIgrisce,
	NkmbIconKartonpoln,
	NkmbIconMenjava,
	NkmbIconPolcasKonec,
	NkmbIconPrvaPostava,
	NkmbIconVstop
} from "@nitra/nkmb-theme";
import React from "react";
import usePlayerDetailsContext from "./PlayerDetailsContext";
import {EPlayerPosition} from "../../../models";

export function PlayerStatsSelectedSeason() {
	const isMobile = useIsMobileContext()
	const {seasonStats, showFullData} = usePlayerStatsContext()
	const data = usePlayerDetailsContext()

	return (
		<Box>
			<Stack
				direction={isMobile ? "column" : "row"}
				spacing={2}
				sx={{justifyContent: "center", flexWrap: "wrap"}}
			>
				<PlayerStatsSingleAttributeElement
					emblem={NkmbIconIgrisce}
					value={seasonStats.appearances}
					title={"nastopi"}/>
				<PlayerStatsSingleAttributeElement
					emblem={NkmbIconPrvaPostava}
					value={seasonStats.firstElevenCount}
					title={"prvih enajst"}/>
				<PlayerStatsSingleAttributeElement
					emblem={NkmbIconVstop}
					value={seasonStats.subInCount}
					title={"v igro"}/>
				{
					showFullData &&
                    <PlayerStatsSingleAttributeElement
                        emblem={NkmbIconMenjava}
                        value={seasonStats.subOutCount}
                        title={"iz igre"}/>
				}
				{
					showFullData &&
                    <PlayerStatsSingleAttributeElement
                        emblem={NkmbIconPolcasKonec}
                        value={seasonStats.minutesPlayed}
                        title={"minute"}/>
				}
				{!(data?.playerPosition === EPlayerPosition.GOALKEEPER) &&
                    <PlayerStatsSingleAttributeElement
                        emblem={NkmbIconGol}
                        value={seasonStats.goals}
                        title={"zadetki"}/>
				}
				{
					showFullData &&
                    <PlayerStatsSingleAttributeElement
                        emblem={NkmbIconKartonpoln}
                        value={seasonStats.yellowCards}
                        title={"rumeni kartoni"}/>
				}
				{
					showFullData &&
                    <PlayerStatsSingleAttributeElement
                        emblem={NkmbIconKartonpoln}
                        value={seasonStats.redCards}
                        title={"rdeči kartoni"}/>

				}
			</Stack>
		</Box>
	)
}