import React, {useState} from "react";
import {SmallLoader} from "../../components/SmallLoader";
import {Redirect} from "@nitra/nkmb-card-components";
import {Box} from "@mui/material";
import {getNewsFromOldId} from "../../firestore";
import {useDebounceEffect} from "../../utilities/useDebounceEffect";

/**
 * This component accepts old links (nkmaribor.com/novice/?id={id}) and fetches them
 * via functions and then redirects to the correct page
 * @constructor
 */
export function OldNewsHandler() {
	const [newNewsId, setNewNewsId] = useState<string | undefined>(undefined);

	const {search} = window.location;
	const params = new URLSearchParams(search);
	const id = params.get("id");

	useDebounceEffect(() => {
		if (id !== null && id !== undefined) {
			getNewsFromOldId(id).then((data) => {
				setNewNewsId(data.firestoreId);
			})
		}
	}, 250, [id])

	if (id === null) {
		return <Redirect to={"/news"}/>
	}

	if (newNewsId === undefined) {
		return (
			<Box sx={{position: "relative", width: "100%", mt: 10, height: "500px", zIndex: 1929293219}}>
				<SmallLoader isLoading={true}/>
			</Box>
		)

	} else {
		return <Redirect to={`/news/${newNewsId}`}/>
	}
}