import {ESupportStaffRole} from "@nitra/nkmb-card-components";
import React, {useEffect, useState} from "react";
import {SupportStaffListFirestoreDocument} from "../../models";
import {getSupportStaff} from "../../firestore";
import {SupportStaffCommonGroup} from "./SupportStaffCommonGroup";
import {Stack} from "@mui/material";

export function SupportStaffView() {
	const [groups, setGroups] = useState<
		{
			label: string,
			staff: SupportStaffListFirestoreDocument[]
		} []>([])

	async function getStaff() {
		getSupportStaff().then((payload) => {
			setGroups([
					{
						label: "Športni direktor",
						staff: [
							...payload.staff.filter((person) => person.role === ESupportStaffRole.SPORTS_DIRECTOR),
							...payload.staff.filter((person) => person.role === ESupportStaffRole.SPORTS_DIRECTOR_ASSISTANT)
						].sort((a, b) => a.position - b.position)
					},
					{
						label: "Trener",
						staff: [
							...payload.staff.filter((person) => person.role === ESupportStaffRole.HEAD_COACH)
						].sort((a, b) => a.position - b.position)
					},

					{
						label: "Pomočniki",
						staff: [
							...payload.staff.filter((person) => person.role === ESupportStaffRole.COACH),
							...payload.staff.filter((person) => person.role === ESupportStaffRole.ASSISTANT_COACH),
							...payload.staff.filter((person) => person.role === ESupportStaffRole.TECHNICAL_COACH),
							...payload.staff.filter((person) => person.role === ESupportStaffRole.GOALKEEPER_COACH),
							...payload.staff.filter((person) => person.role === ESupportStaffRole.CONDITIONING_COACH),
							...payload.staff.filter((person) => person.role === ESupportStaffRole.COORDINATOR),
							...payload.staff.filter((person) => person.role === ESupportStaffRole.ANALYST),
							...payload.staff.filter((person) => person.role === ESupportStaffRole.VIDEO_ANALYST),
						].sort((a, b) => a.position - b.position)
					},
					{
						label: "Zdravniška ekipa",
						staff: [
							...payload.staff.filter((person) => person.role === ESupportStaffRole.DOCTOR),
							...payload.staff.filter((person) => person.role === ESupportStaffRole.PHYSIOTHERAPIST),
						].sort((a, b) => a.position - b.position)
					},
					{
						label: "Ekonomat",
						staff: [
							...payload.staff.filter((person) => person.role === ESupportStaffRole.HEAD_OF_LOGISTICS),
							...payload.staff.filter((person) => person.role === ESupportStaffRole.EQUIPMENT_MANAGER),
							...payload.staff.filter((person) => person.role === ESupportStaffRole.LAUNDRY_MANAGER),
						].sort((a, b) => a.position - b.position)
					},
				]
			)
		})
	}

	useEffect(() => {
		getStaff()
	}, []);

	return (
		<Stack direction={"column"} sx={{
			px: {xs: 2, md: 3},
			mx: 0,
		}}>
			{groups.map((group, index) =>
				<SupportStaffCommonGroup key={index} group={group}/>
			)}
		</Stack>
	)
}