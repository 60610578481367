import {Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {FootballSchoolStaffListFirestoreDocument} from "../../models";
import {getFootballSchoolStaff} from "../../firestore";
import {EFootballSchoolStaffType} from "@nitra/nkmb-card-components";
import {FootballSchoolSupportStaffCommonGroup} from "./FootballSchoolSupportStaffCommonGroup";

export function FootballSchoolStaff() {
	const [groups, setGroups] = useState<
		{
			label: string,
			staff: FootballSchoolStaffListFirestoreDocument[]
		} []>([])

	async function getStaff() {
		getFootballSchoolStaff().then((payload) => {
			setGroups([
					{
						label: "Vodstvo",
						staff: [
							...payload.filter((person) => person.type === EFootballSchoolStaffType.DIRECTOR_OF_FOOTBALL_SCHOOL),
							...payload.filter((person) => person.type === EFootballSchoolStaffType.HEAD_OF_FOOTBALL_SCHOOL),
							...payload.filter((person) => person.type === EFootballSchoolStaffType.SECRETARY),
							...payload.filter((person) => person.type === EFootballSchoolStaffType.HEAD_OF_TALENT_DEVELOPMENT),
							...payload.filter((person) => person.type === EFootballSchoolStaffType.HEAD_SCOUT),
						].sort((a, b) => a.position - b.position)
					},
					{
						label: "Trenerji",
						staff: [
							...payload.filter((person) => person.type === EFootballSchoolStaffType.COACH),
						].sort((a, b) => a.position - b.position)
					},
					{
						label: "Druge službe",
						staff: [
							...payload.filter((person) => person.type === EFootballSchoolStaffType.GOALKEEPER_COACH),
							...payload.filter((person) => person.type === EFootballSchoolStaffType.CONDITIONING_COACH),
							...payload.filter((person) => person.type === EFootballSchoolStaffType.ANALYST),
						].sort((a, b) => a.position - b.position)
					},
					{
						label: "Pomočniki",
						staff: [
							...payload.filter((person) => person.type === EFootballSchoolStaffType.ASSISTANT_COACH),
						].sort((a, b) => a.position - b.position)
					},
				]
			)
		})
	}

	useEffect(() => {
		getStaff()
	}, []);

	return (
		<Stack direction={"column"} sx={{
			px: {xs: 2, md: 3},
			mx: 0,
		}}>
			{groups.map((group, index) =>
				<FootballSchoolSupportStaffCommonGroup key={index} group={group}/>
			)}
		</Stack>
	)
}
