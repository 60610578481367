import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {PaperWidthContainer} from "components/widthControlElements";
import {useEffect, useState} from "react";

import {
	PlayerProfileBannerLeftColumnDesktop
} from "views/team/playerDetailsPage/banner/PlayerProfileBannerLeftColumnDesktop";
import {
	PlayerProfileBannerLeftColumnMobile
} from "views/team/playerDetailsPage/banner/PlayerProfileBannerLeftColumnMobile";
import {
	PlayerProfileBannerNationalFlagDesktop
} from "views/team/playerDetailsPage/banner/PlayerProfileBannerNationalFlagDesktop";
import {
	PlayerProfileBannerNationalFlagMobile
} from "views/team/playerDetailsPage/banner/PlayerProfileBannerNationalFlagMobile";
import usePlayerDetailsContext from "views/team/playerDetailsPage/PlayerDetailsContext";

export function PlayerProfileBanner() {
	const player = usePlayerDetailsContext()
	const isMobile = useIsMobileContext();

	const [bannerUrl, setBannerUrl] = useState<string | undefined>(undefined)
	const [hasBanner, setHasBanner] = useState<boolean>(false)


	useEffect(() => {
		if (!player) return
		if (isMobile) {
			setHasBanner(!!player.image271x271url)
			setBannerUrl(player.image271x271url)
		} else {
			setHasBanner(!!player.image680x680url)
			setBannerUrl(player.image680x680url)
		}
	}, [player, isMobile]);

	if (!player) return <></>

	return (
		<PaperWidthContainer
			sx={{
				backgroundColor: "white",
				backgroundImage: `url(${bannerUrl})`,
				height: hasBanner ? (isMobile ? "271px" : "680px") : "unset",
				position: "relative",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				pt: hasBanner ? 0 : isMobile ? 2 : 5,
				pb: !hasBanner ? 2 : 0,
			}}
			innerSx={{
				height: "100%"
			}}
		>
			{isMobile ? <PlayerProfileBannerLeftColumnMobile/> : <PlayerProfileBannerLeftColumnDesktop/>}
			{isMobile ? <PlayerProfileBannerNationalFlagMobile forceRight={!hasBanner}/> :
				<PlayerProfileBannerNationalFlagDesktop/>}
		</PaperWidthContainer>
	)
}

