import {AppBar, useTheme} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import React, {useEffect, useRef, useState} from "react";
import {DebugBreakpoints} from "./DebugBreakpoints";
import {DebugGutters} from "./DebugGutters";
import {DesktopAppBar} from "./DesktopAppBar";
import {MobileAppBar} from "./MobileAppBar";
import {MobileDrawer} from "./MobileDrawer";
import useAppBarHeightContext from "../ContextProviders/AppBarHeightContext";


const NkAppBar = () => {
	const theme = useTheme()
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const [hiddenItems, setHiddenItems] = useState<Set<number>>(new Set());
	const appBarTextRefs = useRef<(HTMLButtonElement | null)[]>([]);
	const containerRef = useRef<HTMLDivElement>(null);
	const searchIconRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		const updateHiddenItems = () => {
			if (containerRef.current && searchIconRef.current && appBarTextRefs.current) {
				const searchIconLeft = searchIconRef.current.getBoundingClientRect().left;
				let newHiddenItems = new Set<number>();

				appBarTextRefs.current.forEach((buttonRef, index) => {
					if (buttonRef) {
						const buttonRight = buttonRef.getBoundingClientRect().right;

						if (buttonRight >= searchIconLeft) {
							newHiddenItems.add(index);
						}
					}
				});

				setHiddenItems(newHiddenItems);
			}
		};


		const handleResize = () => {
			updateHiddenItems();
		};

		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, [containerRef, searchIconRef, appBarTextRefs]);

	const handleDrawerToggle = () => {
		setIsDrawerOpen((prevState) => !prevState);
	};

	const isMobile = useIsMobileContext();
	const {heightWithBlackBarWithPx} = useAppBarHeightContext();


	return (
		<AppBar position={"fixed"}
		        sx={{
			        zIndex: theme.zIndex.drawer + 1,
			        height: heightWithBlackBarWithPx,
		        }}
		>
			{isMobile ?
				<MobileAppBar
					handleDrawerToggle={handleDrawerToggle}
					isDrawerOpen={isDrawerOpen}
					searchIconRef={searchIconRef}
				/>
				:
				<DesktopAppBar
					hiddenItems={hiddenItems}
					searchIconRef={searchIconRef}
					appBarTextRefs={appBarTextRefs}
					isDrawerOpen={isDrawerOpen}
					handleDrawerToggle={handleDrawerToggle}
					containerRef={containerRef}
				/>
			}

			{/*TODO remove the gutters stuff for prod*/}
			<DebugGutters/>
			<DebugBreakpoints/>

			<MobileDrawer
				open={isDrawerOpen}
				handleDrawerToggle={handleDrawerToggle}
				isLoggedIn={true}
			/>
		</AppBar>
	)
}

export default NkAppBar

