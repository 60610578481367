import {Box, Divider, Stack, Typography, useTheme} from "@mui/material";
import {InnerWidthContainer} from "components/widthControlElements";
import React, {useEffect, useRef, useState} from "react";

import {ClubBanner} from "../ClubBanner";
import {getSeasonTrophiesList} from "../../../firestore";
import {
	ECompetitionType,
	SeasonTrophiesDesktopCard,
	SeasonTrophiesDesktopListFirestoreDocument,
	SeasonTrophiesMobileCard,
	SeasonTrophiesMobileListFirestoreDocument,
	ShareArea,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import {CardContainerWithName} from "../../../components/CardContainerWithName";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {NkmbIconTrophyPokal, NkmbIconTrophySnl, NkmbIconTrophySuperpokal} from "@nitra/nkmb-theme";
import {useNavigate, useParams} from "react-router-dom";

export function ClubTrophies() {
	const [mobileData, setMobileData] = useState<SeasonTrophiesMobileListFirestoreDocument[]>([])
	const [nzsData, setNzsData] = useState<SeasonTrophiesDesktopListFirestoreDocument[]>([])
	const [championsLeagueData, setChampionsLeagueData] = useState<SeasonTrophiesDesktopListFirestoreDocument[]>([])
	const [europaLeagueData, setEuropaData] = useState<SeasonTrophiesDesktopListFirestoreDocument[]>([])
	const [intertotoData, setIntertotoData] = useState<SeasonTrophiesDesktopListFirestoreDocument[]>([])

	const [leagueWinnerCount, setLeagueWinnerCount] = useState<number>(0)
	const [cupWinnerCount, setCupWinnerCount] = useState<number>(0)
	const [superCupWinnerCount, setSuperCupWinnerCount] = useState<number>(0)

	const [selectedYear, setSelectedYear] = useState<number>(0)
	const [selectedYearData, setSelectedYearData] = useState<SeasonTrophiesMobileListFirestoreDocument | undefined>(undefined)
	const [selectableYears, setSelectableYears] = useState<number[]>([])

	const yearRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
	const scrollableContainerRef = useRef<HTMLDivElement | null>(null);

	const theme = useTheme();
	const isMobile = useIsMobileContext()
	const {isIos} = useIosContext()
	const {selectedSeason} = useParams()
	const navigate = useNavigate()


	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (mobileData.length > 0) {
			const years = mobileData.map((item) => item.season);
			setSelectableYears(years);
		}
	}, [mobileData]);

	useEffect(() => {
		if (selectableYears.length > 0) {
			const seasonFromUrl = selectedSeason ? parseInt(selectedSeason) : undefined;

			if (seasonFromUrl && selectableYears.includes(seasonFromUrl)) {
				if (selectedYear !== seasonFromUrl) {
					setSelectedYear(seasonFromUrl);
				}
			} else {
				const firstYear = selectableYears[0];
				if (selectedYear !== firstYear) {
					setSelectedYear(firstYear);
				}
				if (selectedSeason !== String(firstYear)) {
					navigate(`/club/trophies/${firstYear}`, {replace: true});
				}
			}
		}
	}, [selectableYears, selectedSeason]);

	useEffect(() => {
		if (selectedYear !== 0) {
			const yearData = mobileData.find((item) => item.season === selectedYear);
			setSelectedYearData(yearData);
		}
	}, [selectedYear, mobileData]);

	useEffect(() => {
		const el = yearRefs.current[selectedYear];
		const container = scrollableContainerRef.current;
		if (el && container) {
			const containerWidth = container.clientWidth;
			const containerScrollWidth = container.scrollWidth;
			const maxScrollLeft = containerScrollWidth - containerWidth;

			// Calculate the position to center the element
			let newScrollLeft = el.offsetLeft + el.offsetWidth / 2 - containerWidth / 2;

			// Constrain newScrollLeft within valid scroll range
			newScrollLeft = Math.max(0, Math.min(newScrollLeft, maxScrollLeft));

			container.scrollTo({
				left: newScrollLeft,
				behavior: 'smooth',
			});
		}
	}, [selectedYear]);


	const handleYearChange = (year: number) => {
		if (selectedYear !== year) {
			setSelectedYear(year);
			navigate(`/club/trophies/${year}`);
		}
	};

	async function getData() {
		getSeasonTrophiesList().then((data) => {
			setMobileData(data.mobile)
			setNzsData(data.nzs)

			setChampionsLeagueData(data.uefa.filter((item) => item.competitionType === ECompetitionType.CHAMPIONS_LEAGUE))
			setEuropaData(data.uefa.filter((item) => item.competitionType === ECompetitionType.EUROPA_LEAGUE))
			setIntertotoData(data.uefa.filter((item) => item.competitionType === ECompetitionType.INTERTOTO))

			setLeagueWinnerCount(data.leagueWinnerCount)
			setCupWinnerCount(data.cupWinnerCount)
			setSuperCupWinnerCount(data.superCupWinnerCount)

			setSelectableYears(data.mobile.map((item) => item.season))
			setSelectedYear(data.mobile[0].season)
		})
	}

	if (isMobile) {
		return (
			<Stack>
				<Box
					sx={{
						position: "relative",
						backgroundImage: "url('/images/club/banners/trophies-mobile.png')",
						backgroundSize: "cover",
						backgroundPosition: "center",
						height: "300px",
						width: "100%",
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "stretch",
						color: "white",
						fontSize: "24px",
						fontWeight: "bold"
					}}
				>
					<Box>
						<div style={{
							backgroundImage: "linear-gradient(to bottom,rgba(0, 0, 0, 0) 60%,rgba(18, 18, 18, 1.0))",
							// zIndex: 0,
							position: "absolute", top: 0, left: 0, right: 0, bottom: 0
						}}/>
					</Box>
					<Box sx={{
						position: "relative",
						display: "flex",
						alignItems: "start",
						justifyContent: "flex-end",
						flexDirection: "column",
						width: "100%",
						px: 2
					}}>
						<Stack sx={{width: "100%"}} direction={"row"}>
							<Typography variant={"h3"}
							            sx={{
								            color: theme.palette.background.paper,
								            pb: 2,
								            [theme.breakpoints.down("sm")]: isIos && {
									            '@media (-webkit-min-device-pixel-ratio: 2)': {
										            fontWeight: 500, // Adjust font weight for iPhones
									            },
								            },
							            }}
							>Lovorike</Typography>
							<ShareArea sx={{ml: "auto"}} brightText={true}/>
						</Stack>
						{/*<Stack sx={{width: "100%"}} direction={"row"}>*/}
						{/*	<Typography variant={"bodyL"} sx={{*/}
						{/*		color: theme.palette.background.paper,*/}
						{/*		pb: 1,*/}
						{/*	}}>film o mojem mestu in klubu</Typography>*/}
						{/*	<ShareArea sx={{ml: "auto"}} brightText={true}/>*/}
						{/*</Stack>*/}
					</Box>
				</Box>
				<Divider/>
				<Stack direction={"row"} sx={{backgroundColor: theme.palette.text.primary, height: "50px"}}
				       alignItems={"center"} spacing={2}>
					<NkmbIconTrophySnl
						style={{height: 28, width: 28, paddingLeft: "16px", color: theme.palette.background.default}}/>
					<Typography variant={"gumbS"}
					            sx={{color: theme.palette.background.default, flexGrow: 1, textTransform: "uppercase"}}>Državni
						prvak</Typography>
					<Typography variant={"h5"}
					            sx={{color: theme.palette.background.default, pr: 4}}> {leagueWinnerCount}</Typography>
				</Stack>
				<Divider/>
				<Stack direction={"row"} sx={{backgroundColor: theme.palette.text.primary, height: "50px"}}
				       alignItems={"center"} spacing={2}>
					<NkmbIconTrophyPokal
						style={{height: 28, width: 28, paddingLeft: "16px", color: theme.palette.background.default}}/>
					<Typography variant={"gumbS"}
					            sx={{color: theme.palette.background.default, flexGrow: 1, textTransform: "uppercase"}}>Pokalni
						zmagovalec</Typography>
					<Typography variant={"h5"}
					            sx={{color: theme.palette.background.default, pr: 4}}> {cupWinnerCount}</Typography>
				</Stack>
				<Divider/>
				<Stack direction={"row"} sx={{backgroundColor: theme.palette.text.primary, height: "50px"}}
				       alignItems={"center"} spacing={2}>
					<NkmbIconTrophySuperpokal
						style={{height: 28, width: 28, paddingLeft: "16px", color: theme.palette.background.default}}/>
					<Typography variant={"gumbS"}
					            sx={{color: theme.palette.background.default, flexGrow: 1, textTransform: "uppercase"}}>Zmagovalec
						superpokala</Typography>
					<Typography variant={"h5"} sx={{
						color: theme.palette.background.default,
						pr: 4
					}}> {superCupWinnerCount}</Typography>
				</Stack>
				<Stack
					direction={"row"}
					ref={scrollableContainerRef}
					sx={{
						pt: 5,
						pb: 2,
						overflowY: "Scroll",
						px: 2,
					}}
					alignItems={"center"}
					spacing={4}
				>{selectableYears.map((item) =>
					<Box key={item.toString()}
					     ref={(el: HTMLDivElement) => {
						     yearRefs.current[item] = el;
					     }}
					     onClick={() => handleYearChange(item)}>
						<Typography
							sx={{opacity: item === selectedYear ? 1 : 0.4, cursor: "pointer"}}
							variant={item === selectedYear ? "h2" : "h4"}>
							{item}
						</Typography>
					</Box>)
				}</Stack>
				<Stack direction={"row"}
				       sx={{
					       overflowY: "Scroll",
					       pl: 2,
					       pb: 2,
					       pr: 2,
					       pt: 2,
				       }}
				       spacing={1}>
					{selectedYearData?.wonLeague &&
                        <SeasonTrophiesMobileCard trophyImage={selectedYearData.leagueTrophyImageUrl!!}
                                                  title={"Državni prvak"}
                                                  sx={{minWidth: "148px"}}
                                                  relativeUrl={`/club/trophies/nzs/${selectedYearData.key}`}/>}
					{selectedYearData?.wonCup &&
                        <SeasonTrophiesMobileCard trophyImage={selectedYearData.cupTrophyImageUrl!!}
                                                  title={"Pokalni zmagovalec"}
                                                  sx={{minWidth: "148px"}}
                                                  relativeUrl={`/club/trophies/nzs/${selectedYearData.key}`}/>}
					{selectedYearData?.wonSuperCup &&
                        <SeasonTrophiesMobileCard trophyImage={selectedYearData.superCupTrophyImageUrl!!}
                                                  title={"Superpokal"}
                                                  sx={{minWidth: "148px"}}
                                                  relativeUrl={`/club/trophies/nzs/${selectedYearData.key}`}/>}
					{selectedYearData?.showUefa &&
                        <SeasonTrophiesMobileCard trophyImage={selectedYearData.uefaTrophyImageUrl!!}
                                                  title={selectedYearData.uefaTitle ?? "UEFA"}
                                                  sx={{minWidth: "148px"}}
                                                  relativeUrl={`/club/trophies/uefa/${selectedYearData.key}`}/>}
				</Stack>
			</Stack>
		)
	} else {
		return (
			<Stack>
				<ClubBanner
					url={"/images/club/banners/trophies.png"}
					mainText={"Lovorike"}
				/>
				<InnerWidthContainer>
					<Stack>
						<CardContainerWithName
							title={`${leagueWinnerCount} x državni prvak, ${cupWinnerCount} x pokalni zmagovalec, ${superCupWinnerCount} x superpokal`}
							cardAlignLeft={true}
						>
							{nzsData.map((item) =>
								<SeasonTrophiesDesktopCard
									key={item.key}
									backgroundImage={item.image288x360url!!}
									title={item.season.toString()}
									subTitle={item.cardTitle ?? ""}
									relativeUrl={`/club/trophies/nzs/${item.key}`}
									wonLeague={item.wonLeague}
									wonCup={item.wonCup}
									wonSuperCup={item.wonSuperCup}
								/>
							)}
						</CardContainerWithName>
						<CardContainerWithName
							title={"UEFA Champions League"}
							cardAlignLeft={true}
						>
							{championsLeagueData.map((item) =>
								<SeasonTrophiesDesktopCard
									key={item.key}
									backgroundImage={item.image288x360url!!}
									title={item.season.toString()}
									subTitle={item.cardTitle ?? ""}
									relativeUrl={`/club/trophies/uefa/${item.key}`}
									wonLeague={false}
									wonCup={false}
									wonSuperCup={false}
								/>
							)}
						</CardContainerWithName>
						<CardContainerWithName
							title={"UEFA Europa League"}
							cardAlignLeft={true}
						>
							{europaLeagueData.map((item) =>
								<SeasonTrophiesDesktopCard
									key={item.key}
									backgroundImage={item.image288x360url!!}
									title={item.season.toString()}
									subTitle={item.cardTitle ?? ""}
									relativeUrl={`/club/trophies/uefa/${item.key}`}
									wonLeague={false}
									wonCup={false}
									wonSuperCup={false}
								/>
							)}
						</CardContainerWithName>
						<CardContainerWithName
							title={"UEFA Pokal Intertoto"}
							cardAlignLeft={true}
						>
							{intertotoData.map((item) =>
								<SeasonTrophiesDesktopCard
									key={item.key}
									backgroundImage={item.image288x360url!!}
									title={item.season.toString()}
									subTitle={item.cardTitle ?? ""}
									relativeUrl={`/club/trophies/uefa/${item.key}`}
									wonLeague={false}
									wonCup={false}
									wonSuperCup={false}
								/>
							)}
						</CardContainerWithName>
					</Stack>
				</InnerWidthContainer>
			</Stack>
		)
	}
}

