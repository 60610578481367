import {Stack, Typography, useTheme} from "@mui/material";
import {TextWidthContainer} from "../../components";
import React from "react";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function FootballSchoolNationalTeam() {
	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<TextWidthContainer sx={{pt: 5}}>
			<Stack>
				<Typography variant={"bodyXL"}>
					Nogometna šola našega kluba je skozi vsa leta delovanja v svojih vrstah vzgojila fante, ki ponosno
					predstavljajo nogomet in naše mesto po Sloveniji ter v tujini. Uspešnost dela v nogometni šoli je
					namreč
					mogoče oceniti tudi na podlagi nogometašev, ki so zaigrali v članskih državnih reprezentancah,
					najpomembnejše korake na razvojni poti pa so naredili v nogometni šoli iz Ljudskega vrta.
				</Typography>
				<Typography
					variant={"h5"}
					sx={{
						pt: 2,
						pb: 2,
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}>
					A reprezentati iz Ljudskega vrta in število nastopov:
				</Typography>

				<FootballSchoolNationalTeamPerson name={"Zlatko Zahović"} appearances={84}/>
				<FootballSchoolNationalTeamPerson name={"Marko Simeunović"} appearances={62}/>
				<FootballSchoolNationalTeamPerson name={"Petar Stojanović"} appearances={59}/>
				<FootballSchoolNationalTeamPerson name={"Rene Krhin"} appearances={48}/>
				<FootballSchoolNationalTeamPerson name={"Vid Belec"} appearances={21}/>
				<FootballSchoolNationalTeamPerson name={"Luka Zahović"} appearances={15}/>
				<FootballSchoolNationalTeamPerson name={"Žan Celar"} appearances={15}/>
				<FootballSchoolNationalTeamPerson name={"Armin Bačinović"} appearances={13}/>
				<FootballSchoolNationalTeamPerson name={"Žan Vipotnik"} appearances={11}/>
				<FootballSchoolNationalTeamPerson name={"Igor Poznič"} appearances={7}/>
				<FootballSchoolNationalTeamPerson name={"Luka Krajnc"} appearances={4}/>
				<FootballSchoolNationalTeamPerson name={"Dino Hotić"} appearances={4} otherNation={"BiH"}/>
				<FootballSchoolNationalTeamPerson name={"Rene Mihelič"} appearances={3}/>
				<FootballSchoolNationalTeamPerson name={"Matic Črnic"} appearances={2}/>
				<FootballSchoolNationalTeamPerson name={"Matjaž Kek"} appearances={1}/>
				<FootballSchoolNationalTeamPerson name={"Aleks Pihler"} appearances={1}/>
				<FootballSchoolNationalTeamPerson name={"Dejan Trajkovski"} appearances={1}/>
				<FootballSchoolNationalTeamPerson name={"Sven Šoštarič Karič"} appearances={1}/>
				<FootballSchoolNationalTeamPerson name={"Nino Žugelj"} appearances={1}/>
			</Stack>
		</TextWidthContainer>
	)
}

function FootballSchoolNationalTeamPerson(props: {
	name: string,
	appearances: number,
	otherNation?: string,
}) {
	const {name, appearances, otherNation} = props;
	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<Stack direction={"row"} alignItems={"center"}>
			<Typography variant={"h5"} sx={{
				pl: 1,
				[theme.breakpoints.down("sm")]: isIos && {
					'@media (-webkit-min-device-pixel-ratio: 2)': {
						fontWeight: 500, // Adjust font weight for iPhones
					},
				},
				pr: 1,
			}}>{appearances}</Typography>
			<Typography variant={"bodyXL"}>{name} {otherNation && ` (${otherNation})`}</Typography>
		</Stack>
	)
}