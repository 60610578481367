import {Divider, Stack, Typography, useTheme} from "@mui/material";
import {CardWithShadow, useCurrentSeasonContext} from "@nitra/nkmb-card-components";
import {ENationality, NationFlagRound} from "@nitra/nkmb-theme";
import React from "react";
import {useNavigate} from "react-router-dom";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function LandingPermanentPlayerCardSmall(props: {
	onClickAction: () => void,
	onMouseEnter: () => void,
	onMouseLeave: () => void,
	imageUrl: string,
	scale: number,
	playerNumber: string,
	firstName: string,
	lastName: string,
	nationality: ENationality
}) {
	const {
		onClickAction,
		onMouseLeave,
		onMouseEnter,
		imageUrl,
		scale,
		playerNumber,
		firstName,
		lastName,
		nationality
	} = props;


	const navigate = useNavigate()
	const {currentSeasonString} = useCurrentSeasonContext()
	const theme = useTheme();
	const {isIos} = useIosContext()

	const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.stopPropagation()
		navigate("/team")
	}

	return (
		<CardWithShadow
			onClickSpecialAction={onClickAction}
			sx={{
				display: "flex",
				flexDirection: "column",
				position: "relative",
			}}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<img
				src={imageUrl}
				alt={"igralec"}
				style={{
					position: "absolute",
					top: 0,
					left: 60,
					width: "100%",
					height: "100%",
					objectFit: "contain",
					overflow: "hidden",
					transform: `scale(${scale})`,
					transition: "transform 1.5s",
				}}/>
			<Stack
				direction={"column"}
				justifyContent={"space-between"}
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					height: "100%"
				}}>
				<Stack direction={"column"} sx={{
					'& > :nth-child(1)': {
						paddingLeft: 0,
					},
					'& > :nth-child(n+2)': {
						paddingLeft: 3,
					},
				}}>
					<NationFlagRound
						countryCode={nationality}
						sx={{
							pb: 5,
						}}
						svgProps={{
							width: "80px",
							height: "80px",
						}}
					/>
					<Typography
						variant={"h3"}
						sx={{
							maxWidth: "220px",
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}>
						{playerNumber}
					</Typography>
					<Stack>
						<Typography variant={"podnaslovXS"}>
							{firstName}
						</Typography>
						<Typography variant={"podnaslovXS"}>
							{lastName}
						</Typography>
					</Stack>
				</Stack>
				<Stack
					onClick={handleClick}
					direction={"column"}
					alignItems={"center"}
					sx={{
						mt: "auto",
						backgroundColor: "RGBA(255,255,255,0.9)"
					}}>
					<Divider sx={{width: "100%", backgroundColor: theme.palette.secondary.main, height: 3}}/>
					<Typography variant={"h4"} sx={{
						pb: 1,
						pt: 2,
						color: theme.palette.primary.main,
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}>Prva ekipa</Typography>
					<Typography
						variant={"bodyS"}
						sx={{
							pb: 2,
							color: theme.palette.primary.main
						}}>{currentSeasonString}</Typography>
				</Stack>
			</Stack>
		</CardWithShadow>
	)
}
