import {Button, SxProps, Typography, useTheme} from "@mui/material";
import {Link} from "react-router-dom";

export function VipButton(props: {
	label: string,
	href?: string,
	navigateLink?: string,
	sx?: SxProps
}) {
	const {label, href, navigateLink} = props;

	const theme = useTheme();

	return (
		<Button
			variant={"outlined"}
			color={"inherit"}
			sx={{
				borderRadius: "20px",
				color: theme.palette.background.paper,
				...props.sx
			}}
		>
			<Link
				to={href ? href : navigateLink!!}
				target={href && href.startsWith("https://") ? "_blank" : "_self"}
			>
				<Typography sx={{color: "background.paper"}} variant={"gumbL"}>{label}</Typography>
			</Link>
		</Button>
	)
}
