import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {Box, CardContent, Stack, Typography, useTheme} from "@mui/material";
import {CardOverImageTitle, CardWithShadow} from "@nitra/nkmb-card-components";
import {useNavigate} from "react-router-dom";
import React from "react";
import {VipTexts} from "./VipTexts";

export function VipCommonMobile() {
	const {isIos} = useIosContext()
	const theme = useTheme();
	const navigate = useNavigate()

	return (
		<Box sx={{
			backgroundImage: "url('/images/hospitality/hospitalityBackground.png')",
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			width: '100%',
			pt: "300px",
			pb: 6,
		}}>
			<Stack
				sx={{
					px: 2,
					alignItems: "center",
				}}
				spacing={5}
			>
				<Typography
					variant={"h3"}
					sx={{
						textAlign: "center",
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
						color: theme.palette.background.default
					}}
				>
					Ljudski vrt 5*
				</Typography>
				<CardWithShadow
					sx={{
						display: "flex",
						flexDirection: "column",
						position: "relative",
						width: "100%",
						mt: 8,
						flexGrow: 1,
					}}
					onClickSpecialAction={() => navigate(VipTexts.basic.vps.link)}
					fullSizeImageUrl={`url(${VipTexts.basic.vps.imageUrl})`}
				>
					<CardContent sx={{mt: "auto", zIndex: 1, pb: 0}}>
						<CardOverImageTitle title={VipTexts.basic.vps.imageTitle} variant={"h3"} sx={{
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}/>
					</CardContent>
				</CardWithShadow>
				<CardWithShadow
					sx={{
						display: "flex",
						flexDirection: "column",
						position: "relative",
						width: "100%",
						mt: 8,
						flexGrow: 1,
					}}
					onClickSpecialAction={() => navigate(VipTexts.basic.skybox.link)}
					fullSizeImageUrl={`url(${VipTexts.basic.skybox.imageUrl})`}
				>
					<CardContent sx={{mt: "auto", zIndex: 1, pb: 0}}>
						<CardOverImageTitle title={VipTexts.basic.skybox.imageTitle} variant={"h3"} sx={{
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}/>
					</CardContent>
				</CardWithShadow>
			</Stack>
		</Box>
	)
}