import {DataGrid, GridColumns} from "@mui/x-data-grid";
import {EPlayerPosition, PlayerPlayedMatchStatsFirebaseDocument} from "../../../models";
import {alpha, SvgIcon, Typography, useTheme} from "@mui/material";
import {toLongDate, toNumericDate, useIsMobileContext} from "@nitra/nkmb-card-components";
import {
	NkmbIconGol,
	NkmbIconKartonpoln,
	NkmbIconMenjava,
	NkmbIconPolcasKonec,
	NkmbIconPrvaPostava
} from "@nitra/nkmb-theme";
import React, {useEffect, useState} from "react";
import usePlayerStatsContext from "./PlayerStatsContext";
import typography from "@nitra/nkmb-theme/typography";
import usePlayerDetailsContext from "./PlayerDetailsContext";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {useLocation, useNavigate} from "react-router-dom";


export function PlayerStatsListOfMatches() {
	const {matches} = usePlayerStatsContext()
	const isMobile = useIsMobileContext()
	const theme = useTheme();
	const [columns, setColumns] = useState<GridColumns<PlayerPlayedMatchStatsFirebaseDocument>>([]);
	const data = usePlayerDetailsContext()
	const navigate = useNavigate()
	const location = useLocation()


	useEffect(() => {
		const colList: GridColumns<PlayerPlayedMatchStatsFirebaseDocument> = []

		/**
		 * Round
		 */
		if (!isMobile) {
			colList.push({
				field: "round",
				headerName: "krog",
				align: "left",
				headerAlign: "left",
				width: 50,
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					<Typography sx={{color: "#676767"}} variant={"bodySBold"}>{params.row.round}</Typography>
			})
		}


		/**
		 * Date
		 */
		let dateCol: GridEnrichedColDef<PlayerPlayedMatchStatsFirebaseDocument> = {
			field: "date",
			headerAlign: "left",
			renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
				<Typography sx={{color: "#676767"}}
				            variant={"bodySBold"}>{toLongDate(params.row.date)}</Typography>
		}

		if (isMobile) {
			dateCol = {
				...dateCol,
				renderHeader: () => <Typography variant={"bodySBold"} sx={{
					pl: "4px",
					color: alpha(theme.palette.text.primary, 0.3)
				}}>datum</Typography>,
				align: "center",
				width: 50,
				maxWidth: 50,
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					<Typography sx={{color: "#676767", textWrap: "wrap"}}
					            variant={"bodyS"}>{toNumericDate(params.row.date)}</Typography>
			}
		} else {
			dateCol = {
				...dateCol,
				headerName: "datum",
				align: "left",
				width: 150,
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					<Typography sx={{color: "#676767"}}
					            variant={"bodySBold"}>{toLongDate(params.row.date)}</Typography>
			}
		}
		colList.push(dateCol)

		/**
		 * Match
		 */
		let matchCol: GridEnrichedColDef<PlayerPlayedMatchStatsFirebaseDocument> = {
			field: "match",
			align: "left",
			headerAlign: "left",
			valueGetter: (params: {
				row: PlayerPlayedMatchStatsFirebaseDocument
			}) => `${params.row.homeTeamName} - ${params.row.awayTeamName} ${params.row.score}`,
		}

		if (isMobile) {
			matchCol = {
				...matchCol,
				renderHeader: () => <Typography variant={"bodySBold"} sx={{
					pl: 1,
					color: alpha(theme.palette.text.primary, 0.3)
				}}>tekma</Typography>,
				flex: 1,
				headerAlign: "left",
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					<Typography sx={{color: theme.palette.text.primary, textWrap: "wrap"}}
					            variant={"bodySBold"}>{params.row.homeTeamName} - {params.row.awayTeamName} {params.row.score}</Typography>
			}
		} else {
			matchCol = {
				...matchCol,
				headerName: "tekma",
				flex: 12,
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					<Typography sx={{color: "#676767"}}
					            variant={"bodySBold"}>{params.row.homeTeamName} - {params.row.awayTeamName} {params.row.score}</Typography>
			}
		}
		colList.push(matchCol)

		/**
		 * isFirstEleven
		 */
		let isFirstElevenCol: GridEnrichedColDef<PlayerPlayedMatchStatsFirebaseDocument> = {
			field: "isFirstEleven",
			align: "center",
			headerAlign: "center",
		}
		if (isMobile) {
			isFirstElevenCol = {
				...isFirstElevenCol,
				renderHeader: () => <SvgIcon component={NkmbIconPrvaPostava} viewBox={"0 0 100 100"}
				                             sx={{
					                             height: "28px",
					                             width: "28px",
					                             color: alpha(theme.palette.text.primary, 0.3)
				                             }}/>,
				width: 30,
				minWidth: 30,
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					<SvgIcon component={
						params.row.isFirstEleven ? NkmbIconPrvaPostava : NkmbIconMenjava
					} sx={{width: 28, height: 28, color: alpha(theme.palette.text.primary, 0.3)}}
					         viewBox={"0 0 100 100"}/>,
			}
		} else {
			isFirstElevenCol = {
				...isFirstElevenCol,
				headerName: "status",
				width: 81,
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					<Typography sx={{color: "#676767"}}
					            variant={"bodySBold"}>{params.row.isFirstEleven ? "11" : "menjava"}</Typography>
			}
		}
		colList.push(isFirstElevenCol)

		/**
		 * Goal count
		 */
		if (!(data?.playerPosition === EPlayerPosition.GOALKEEPER)) {
			let goalCountCol: GridEnrichedColDef<PlayerPlayedMatchStatsFirebaseDocument> = {
				field: "goalCount",
				align: "center",
				headerAlign: "center",
			}
			if (isMobile) {
				goalCountCol = {
					...goalCountCol,
					renderHeader: () => <SvgIcon component={NkmbIconGol} viewBox={"0 0 100 100"}
					                             sx={{
						                             height: "28px",
						                             width: "28px",
						                             color: alpha(theme.palette.text.primary, 0.3)
					                             }}/>,
					width: 30,
					minWidth: 30,
					renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
						<Typography sx={{color: "#676767"}}
						            variant={"bodySBold"}>{params.row.goalCount}</Typography>
				}
			} else {
				goalCountCol = {
					...goalCountCol,
					headerName: "goli",

					width: 81,
					renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
						<Typography sx={{color: "#676767"}}
						            variant={"bodySBold"}>{params.row.goalCount}</Typography>
				}
			}
			colList.push(goalCountCol)
		}

		// /**
		//  * Assist count - shown on /team page but not /club page
		//  */
		if (!(data?.playerPosition === EPlayerPosition.GOALKEEPER) && !isMobile && location.pathname.includes("team")) {
			colList.push({
				field: "assistCount",
				align: "center",
				headerAlign: "center",
				headerName: "asistence",
				width: 81,
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					<Typography sx={{color: "#676767"}}
					            variant={"bodySBold"}>{params.row.assistCount}</Typography>
			})
		}

		/**
		 * Got yellow card
		 */
		let gotYellowCardCol: GridEnrichedColDef<PlayerPlayedMatchStatsFirebaseDocument> = {
			field: "gotYellowCard",
			align: "center",
			headerAlign: "center",
		}
		if (isMobile) {
			gotYellowCardCol = {
				...gotYellowCardCol,
				renderHeader: () => <SvgIcon component={NkmbIconKartonpoln}
				                             sx={{width: 28, height: 28, color: "#FECE00"}}
				                             viewBox={"0 0 100 100"}/>,

				width: 30,
				minWidth: 30,
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					params.row.gotYellowCard ?
						<SvgIcon component={NkmbIconKartonpoln} sx={{width: 28, height: 28, color: "#FECE00"}}
						         viewBox={"0 0 100 100"}/> : <></>
			}
		} else {
			gotYellowCardCol = {
				...gotYellowCardCol,
				headerName: "rumeni",
				width: 81,
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					params.row.gotYellowCard ?
						<SvgIcon component={NkmbIconKartonpoln} sx={{width: 40, height: 40, color: "#FECE00"}}
						         viewBox={"0 0 100 100"}/> : <></>
			}
		}
		colList.push(gotYellowCardCol)

		/**
		 * Got red card
		 */
		let gotRedCardCol: GridEnrichedColDef<PlayerPlayedMatchStatsFirebaseDocument> = {
			field: "gotRedCard",
			align: "center",
			headerAlign: "center",
		}
		if (isMobile) {
			gotRedCardCol = {
				...gotRedCardCol,
				renderHeader: () => <SvgIcon component={NkmbIconKartonpoln}
				                             sx={{width: 28, height: 28, color: "#C20831"}}
				                             viewBox={"0 0 100 100"}/>,
				width: 30,
				minWidth: 30,
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					params.row.gotRedCard ?
						<SvgIcon component={NkmbIconKartonpoln} sx={{width: 28, height: 28, color: "#C20831"}}
						         viewBox={"0 0 100 100"}/> : <></>
			}
		} else {
			gotRedCardCol = {
				...gotRedCardCol,
				headerName: "rdeči",
				width: 81,
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					params.row.gotRedCard ?
						<SvgIcon component={NkmbIconKartonpoln} sx={{width: 40, height: 40, color: "#C20831"}}
						         viewBox={"0 0 100 100"}/> : <></>
			}
		}
		colList.push(gotRedCardCol)

		/**
		 * Minutes played
		 */
		let minutesPlayedCol: GridEnrichedColDef<PlayerPlayedMatchStatsFirebaseDocument> = {
			field: "minutesPlayed",
			align: "center",
			headerAlign: "center",
		}
		if (isMobile) {
			minutesPlayedCol = {
				...minutesPlayedCol,
				renderHeader: () => <SvgIcon component={NkmbIconPolcasKonec} viewBox={"0 0 100 100"}
				                             sx={{
					                             height: "28px",
					                             width: "28px",
					                             color: alpha(theme.palette.text.primary, 0.3)
				                             }}/>,

				width: 30,
				minWidth: 30,
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					<Typography sx={{color: "#676767"}}
					            variant={"bodySBold"}>{params.row.minutesPlayed}</Typography>
			}
		} else {
			minutesPlayedCol = {
				...minutesPlayedCol,
				headerName: "minute",
				width: 81,
				renderCell: (params: { row: PlayerPlayedMatchStatsFirebaseDocument }) =>
					<Typography sx={{color: "#676767"}}
					            variant={"bodySBold"}>{params.row.minutesPlayed}</Typography>
			}
		}

		colList.push(minutesPlayedCol)
		setColumns(colList)
	}, [isMobile, data]);


	return (
		<DataGrid columns={columns}
		          rows={matches}
		          getRowId={(row) => row.key}
		          hideFooterPagination={false}
		          hideFooter={false}
		          autoHeight={true}
		          disableColumnMenu={true}
		          headerHeight={isMobile ? 31 : 40}
		          rowHeight={isMobile ? 40 : 31}
		          sx={{
			          '& .MuiDataGrid-columnSeparator': {
				          display: "none"
			          },
			          '& .MuiDataGrid-columnHeaders': {
				          backgroundColor: isMobile ? alpha(theme.palette.text.primary, 0.03) : theme.palette.background.default,
				          height: "30px",
				          minHeight: "30px",
			          },
			          '& .MuiDataGrid-columnHeaderTitle': {
				          ...typography.bodySBold,
			          },
			          "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader": {
				          height: "30px",
				          p: 0,
			          },
			          '& .MuiDataGrid-cell': {
				          pl: 1,
				          pr: 1
			          },
			          '& .MuiDataGrid-footerContainer': {
				          backgroundColor: theme.palette.background.default,
				          // height: "9px",
				          // minHeight: "9px",
				          borderBottomLeftRadius: "8px",
				          borderBottomRightRadius: "8px",
			          },
			          '& .MuiDataGrid-row': {
				          cursor: "pointer",
			          },
			          border: "0",
			          backgroundColor: theme.palette.background.paper,
			          borderRadius: "16px",
			          mt: 5,
			          ml: -2,
			          mr: -2,
		          }}
		          initialState={{
			          sorting: {
				          sortModel: [{field: "date", sort: "desc"}]
			          }
		          }}
		          onRowClick={(params) => {
			          if (params.row.reportId) navigate(`/news/${params.row.reportId}`)
		          }}
		/>
	)
}