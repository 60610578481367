import {Stack} from "@mui/material";
import Banner from "components/Banner";
import {ContentContainer} from "components/ContentRender/ContentContainer";
import {ContentElementPageCacheFirebaseDocumentUnion} from "models";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getSeasonTrophiesText} from "../../../firestore";

export function ClubTrophy() {
	const {key, competition} = useParams()

	const [content, setContent] = useState<
		ContentElementPageCacheFirebaseDocumentUnion[]
	>([]);

	const [title, setTitle] = useState("");
	const [subtitle, setSubtitle] = useState("");
	const [image1440x680url, setImage1440x680url] = useState("");

	useEffect(() => {
		if (key === undefined || competition === undefined) {
			return;
		}
		getData(competition, key)
	}, []);

	const getData = (competition: string, key: string) => {
		getSeasonTrophiesText(competition, key).then((result) => {
			setTitle(result.title ?? "");
			setImage1440x680url(result.image1440x680url ?? "");
			setContent(result.content ?? []);
			setSubtitle(result.subtitle ?? "");
		})
	}

	return (
		<Stack>
			<Banner title={title} subtitle={subtitle} imageUrl={image1440x680url} desktopHeight={680} />
			<ContentContainer content={content}/>
		</Stack>
	)
}
