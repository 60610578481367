import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ArticleListFirestoreDocument} from "@nitra/nkmb-card-components";
import {ContentElementPageCacheFirebaseDocumentUnion} from "../../models";
import {NewsArticleHeader} from "../news/article/NewsArticleHeader";
import {ContentContainer} from "../../components/ContentRender/ContentContainer";
import {NewsArticleFooter} from "../news/article/NewsArticleFooter";
import {getFootballSchoolNewsArticle} from "../../firestore";

export function FootballSchoolNewsArticle() {
	const {id} = useParams();

	const [content, setContent] = useState<
		ContentElementPageCacheFirebaseDocumentUnion[]
	>([]);
	const [title, setTitle] = useState("");
	const [createdAt, setCreatedAt] = useState<Date>(new Date());
	const [imageUrl, setImageUrl] = useState("");
	const [suggestedArticles, setSuggestedArticles] = useState<ArticleListFirestoreDocument[]>([]);
	const [nextArticle, setNextArticle] = useState<ArticleListFirestoreDocument | undefined>(undefined);

	useEffect(() => {
		if (!id) {
			console.error("No id provided");
			return;
		}
		getFootballSchoolNewsArticle(id).then((article) => {
			setContent(article.content);
			setTitle(article.title);
			setCreatedAt(article.createdAt);
			if (article.image1440x680url) setImageUrl(article.image1440x680url);
			setSuggestedArticles(article.suggestedArticles);
			setNextArticle(article.nextFootballSchoolArticle);
		}, (error) => {
			console.error(error);
		})
	}, [id])


	return (
		<Box sx={{position: "relative", width: "100%", maxWidth: "1440px"}}>
			<NewsArticleHeader title={title} imageUrl={imageUrl} createdAt={createdAt}/>
			<ContentContainer content={content}/>
			<NewsArticleFooter
				suggestedArticles={
					suggestedArticles.map((item) => ({
						...item,
						hideTitle: false
					} as ArticleListFirestoreDocument))}
				nextArticle={!!nextArticle ? {
					...nextArticle,
					hideTitle: false
				} as ArticleListFirestoreDocument : undefined}/>
		</Box>
	);
}