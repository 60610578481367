import {useMediaQuery, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import RandomPlayerFirebaseDocument from "../../../models/firestore/common/RandomPlayerFirestoreDocument";
import {LandingPermanentPlayerCardLarge} from "./LandingPermanentPlayerCardLarge";
import {LandingPermanentPlayerCardSmall} from "./LandingPermanentPlayerCardSmall";
import {ENationality} from "@nitra/nkmb-theme";

function LandingPermanentTeamCard(props: {
	player: RandomPlayerFirebaseDocument | undefined
}) {
	const {player} = props;

	const navigate = useNavigate()

	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down('md'));

	const [scale, setScale] = useState(1);
	const [imageUrl, setImageUrl] = useState("url(/images/landing/placeholder_igralec.png)");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [playerNumber, setPlayerNumber] = useState("");
	const [nationality, setNationality] = useState<ENationality>(ENationality.ANTARCTICA);

	const onMouseEnter = () => {
		setScale(1.08)
	}

	const onMouseLeave = () => {
		setScale(1)
	}

	useEffect(() => {
		if (player) {
			setImageUrl(player.url360x360)
			setFirstName(player.firstName)
			setLastName(player.lastName)
			setPlayerNumber(player.jerseyNumber.toString())
			setNationality(player.nationality)
		} else {
			setImageUrl("url(/images/landing/placeholder_igralec.png)")
			setFirstName("")
			setLastName("")
			setPlayerNumber("0")
			setNationality(ENationality.ANTARCTICA)
		}
	}, [player])

	return isSmall ?
		<LandingPermanentPlayerCardSmall
			onClickAction={() => navigate(`/team/player/${player?.key}/profile`)}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			imageUrl={imageUrl}
			scale={scale}
			playerNumber={playerNumber}
			firstName={firstName}
			lastName={lastName}
			nationality={nationality}
		/> :
		<LandingPermanentPlayerCardLarge
			onClickAction={() => navigate(`/team/player/${player?.key}/profile`)}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			imageUrl={imageUrl}
			scale={scale}
			playerNumber={playerNumber}
			firstName={firstName}
			lastName={lastName}
			nationality={nationality}
		/>
}


export default LandingPermanentTeamCard
