import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {CardContent, Stack, Typography, useTheme} from "@mui/material";
import {
	CardOverImageTitle,
	CardWithShadow,
	LargeImageCard,
	useBreakpointContext,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import {useNavigate} from "react-router-dom";
import {VipTexts} from "./VipTexts";
import {VipButton} from "./VipButton";
import React from "react";

export function VipCommonDesktop() {

	const {isIos} = useIosContext()
	const theme = useTheme();
	const isMobile = useIsMobileContext()
	const navigate = useNavigate()
	const breakpoint = useBreakpointContext();

	return (
		<>
			<Stack sx={{
				backgroundImage: "url('/images/hospitality/hospitalityBackground.png')",
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				minHeight: '100vh', // Set the minimum height to cover the full viewport
				width: '100%', // Set the width to cover the full viewport
				pt: "650px",
				pb: "150px",
				alignItems: "center",
			}}>
				<Typography
					variant={isMobile ? "h3" : "h1"}
					sx={{
						textAlign: "center",
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
						color: theme.palette.background.default
					}}
				>
					Ljudski vrt 5*
				</Typography>
				<Stack direction={breakpoint == "xl" ? "row" : "column"} sx={{pt: "150px", alignItems: "stretch"}}
				       spacing={6}>
					<LargeImageCard
						title={VipTexts.basic.vps.imageTitle}
						imageUrl={VipTexts.basic.vps.imageUrl}
						onClick={() => navigate(VipTexts.basic.vps.link)}/>
					<Stack sx={{
						maxWidth: "600px",
						flexGrow: 1,
						color: theme.palette.background.paper,
						display: isMobile ? "none" : "flex",
					}}>
						<Typography variant={"h3"} sx={{
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}>{VipTexts.basic.vps.textTitle}</Typography>
						<Typography
							variant={"body1"}
							align={"justify"}
							sx={{
								pt: 2,
							}}
						>{VipTexts.basic.vps.textBody}</Typography>
						<VipButton
							label={VipTexts.basic.vps.buttonLabel}
							sx={{
								mt: "auto",
								mr: "auto",
							}}
							navigateLink={VipTexts.basic.vps.link}
						/>
					</Stack>
				</Stack>
				{
					isMobile ?
						<CardWithShadow
							sx={{
								display: "flex",
								flexDirection: "column",
								position: "relative",
								width: "100%",
								mt: 8,
								flexGrow: 1,
							}}
							onClickSpecialAction={() => navigate(VipTexts.basic.skybox.link)}
							fullSizeImageUrl={`url(${VipTexts.basic.skybox.imageUrl})`}
						>
							<CardContent sx={{mt: "auto", zIndex: 1, pb: 0}}>
								<CardOverImageTitle title={VipTexts.basic.skybox.imageTitle} variant={"h3"} sx={{
									[theme.breakpoints.down("sm")]: isIos && {
										'@media (-webkit-min-device-pixel-ratio: 2)': {
											fontWeight: 500, // Adjust font weight for iPhones
										},
									},
								}}/>
							</CardContent>
						</CardWithShadow>

						:


						<Stack direction={breakpoint == "xl" ? "row" : "column"} sx={{pt: 8, alignItems: "stretch"}}
						       spacing={6}>
							<LargeImageCard
								title={VipTexts.basic.skybox.imageTitle}
								imageUrl={VipTexts.basic.skybox.imageUrl}
								onClick={() => navigate(VipTexts.basic.skybox.link)}/>
							<Stack sx={{
								maxWidth: "600px",
								flexGrow: 1,
								color: theme.palette.background.paper,
							}}>
								<Typography variant={"h3"} sx={{
									[theme.breakpoints.down("sm")]: isIos && {
										'@media (-webkit-min-device-pixel-ratio: 2)': {
											fontWeight: 500, // Adjust font weight for iPhones
										},
									},
								}}>{VipTexts.basic.skybox.textTitle}</Typography>
								<Typography
									variant={"body1"}
									align={"justify"}
									sx={{
										pt: 2,
									}}
								>{VipTexts.basic.skybox.textBody}</Typography>
								<VipButton
									label={VipTexts.basic.skybox.buttonLabel}
									sx={{
										mt: "auto",
										mr: "auto",
									}}
									navigateLink={VipTexts.basic.skybox.link}
								/>
							</Stack>
						</Stack>
				}
			</Stack>
		</>
	)
}