import {Route, Routes} from "react-router-dom";
import {FootballSchoolBasic} from "./FootballSchoolBasic";
import {FootballSchoolPrimarySchools} from "views/footballSchool/FootballSchoolPrimarySchools";
import {FootballSchoolSelection} from "views/footballSchool/FootballSchoolSelection";
import {NavigationBar} from "../../components/NavigationBar/NavigationBar";
import {Stack} from "@mui/material";
import {Redirect} from "@nitra/nkmb-card-components";
import React from "react";
import {FootballSchoolStaff} from "./FootballSchoolStaff";
import {FootballSchoolNewsArticle} from "./FootballSchoolNewsArticle";
import {FootballSchoolNewsFeedContainer} from "./FootballSchoolNewsFeedContainer";

export const selections = ["u-7", "u-8", "u-9", "u-10", "u-11", "u-12", "u-13", "u-14", "u-15", "u-17", "u-19"]

const tabs = [{
	label: "Šola",
	url: "basic",
}, {
	label: "ONŠ",
	url: "primarySchools",
}, {
	label: "Novice",
	url: "news",
}, {
	label: "Strokovni štab",
	url: "staff",

}, {
	label: "Selekcije",
	url: "selection",
}]

export function FootballSchoolRouter() {
	return (
		<Stack>
			<NavigationBar tabs={tabs}/>
			<Routes>
				<Route path={"/basic/*"} element={<FootballSchoolBasic/>}/>
				<Route path={"/primarySchools/*"} element={<FootballSchoolPrimarySchools/>}/>

				<Route path={"/news"} element={<FootballSchoolNewsFeedContainer/>}/>
				<Route path={"/news/:id"} element={<FootballSchoolNewsArticle/>}/>

				<Route path={"/staff"} element={<FootballSchoolStaff/>}/>
				<Route path={"/selection/:key"} element={<FootballSchoolSelection/>}/>

				<Route path={"/selection"} element={<Redirect to={"u-19"}/>}/>
				<Route path={"/"} element={<Redirect to={"basic"}/>}/>
			</Routes>
		</Stack>
	)
}


