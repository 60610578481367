import {ButtonTabNavigatorWithRouter, useSeasonContext} from "@nitra/nkmb-card-components";
import {getPlayerScoresAppearances} from "firestore/specificGetters/StatisticsFirestore";
import {StatisticsPlayerShooterAppearanceFirestoreDocument, StatisticsPlayerShooterAppearanceFlat} from "models";
import React, {useEffect, useState} from "react";
import {
	MobilePlayerShooterAppearanceStatistics
} from "views/club/statistics/mobile/player/MobilePlayerShooterAppearanceStatistics";

export function MobilePlayerStatistics() {
	const {season} = useSeasonContext();
	const [scorers, setScorers] = useState<StatisticsPlayerShooterAppearanceFirestoreDocument[]>([])
	const [appearances, setAppearances] = useState<StatisticsPlayerShooterAppearanceFirestoreDocument[]>([])

	const [filteredScorers, setFilteredScorers] = useState<StatisticsPlayerShooterAppearanceFlat[]>([])
	const [filteredAppearances, setFilteredAppearances] = useState<StatisticsPlayerShooterAppearanceFlat[]>([])

	useEffect(() => {
		get()
	}, []);

	async function get() {
		getPlayerScoresAppearances().then((payload) => {
			setScorers(payload.scorers)
			setAppearances(payload.appearances)
		})
	}

	useEffect(() => {
		setFilteredScorers(scorers
			.filter(scorer => scorer.data.some(data => data.season == season))
			.map(scorer => {
					const data = scorer.data.find(data => data.season == season)
					return {
						key: scorer.key,
						firstName: scorer.firstName,
						lastName: scorer.lastName,
						league: data?.league,
						cup: data?.cup,
						uefa: data?.uefa,
						total: data?.total,
					} as StatisticsPlayerShooterAppearanceFlat
				}
			)
			.sort((a, b) => b.total - a.total)
		)
		setFilteredAppearances(appearances
			.filter(appearance => appearance.data.some(data => data.season == season))
			.map(appearance => {
					const data = appearance.data.find(data => data.season == season)
					return {
						key: appearance.key,
						firstName: appearance.firstName,
						lastName: appearance.lastName,
						league: data?.league,
						cup: data?.cup,
						uefa: data?.uefa,
						total: data?.total,
					} as StatisticsPlayerShooterAppearanceFlat
				}
			)
			.sort((a, b) => b.total - a.total)
		)

	}, [season, appearances, scorers]);

	const tabs = [{
		path: "shooters",
		label: "Naj strelci",
		component: <MobilePlayerShooterAppearanceStatistics title={"Najboljši strelci"}
		                                                    players={filteredScorers}/>
	}, {
		path: "appearances",
		label: "Naj nastopi",
		component: <MobilePlayerShooterAppearanceStatistics title={"Naj nastopi"}
		                                                    players={filteredAppearances}/>
	}]

	return (
		<ButtonTabNavigatorWithRouter tabs={tabs} sx={{px: 0, pt: 2}}/>
	)
}
