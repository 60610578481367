import {Stack, Typography} from "@mui/material";
import {ClubBanner} from "views/club/ClubBanner";
import {InnerWidthContainer, TextWidthContainer} from "../../components";
import React from "react";
import {ButtonTabNavigatorWithRouter} from "@nitra/nkmb-card-components";
import {FootballSchoolContact} from "./FootballSchoolContact";
import {FootballSchoolVision} from "./FootballSchoolVision";
import {FootballSchoolBasicText} from "./FootballSchoolBasicText";
import {FootballSchoolNationalTeam} from "./FootballSchoolNationalTeam";

const tabs = [{
	label: "Šola",
	path: "school",
	component: <FootballSchoolBasicText/>
}, {
	label: `Po${String.fromCharCode(8203)}${String.fromCharCode(173)}slanstvo`,
	path: "vision",
	component: <FootballSchoolVision/>
}, {
	label: `A Repre${String.fromCharCode(8203)}${String.fromCharCode(173)}zentanti`,
	path: "nationalTeam",
	component: <FootballSchoolNationalTeam/>
}, {
// 	label: "Vpis",
// 	path: "signup",
// 	component: <FootballSchoolSignup/>
// }, {
	label: "Kontakti",
	path: "contact",
	component: <FootballSchoolContact/>
}]

export function FootballSchoolBasic() {
	return (
		<Stack alignItems={"center"}>
			<ClubBanner
				url={"/images/football-school-banner.png"}
				mainText={"Nogometna šola NK Maribor"}
				alignText={"stretch"}
			/>
			<InnerWidthContainer>
				<ButtonTabNavigatorWithRouter tabs={tabs} sx={{
					// wordWrap: "break-word",
					// hyphens: "auto",
				}}/>
			</InnerWidthContainer>
		</Stack>
	)
}

export function FootballSchoolSignup() {
	return (
		<TextWidthContainer sx={{pt: 5}}>
			{/*TODO */}
			<Typography variant={"bodyXL"}>Čakam tekst</Typography>
		</TextWidthContainer>
	)
}


