import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {Box, Divider, IconButton, Stack, Typography, useTheme} from "@mui/material";
import {
	getCompetitionNameFromType,
	MatchCallToActionButton,
	MatchListElementFirestoreDocument,
	MatchVsContainer,
	useTimeManagementContext
} from "@nitra/nkmb-card-components";
import {areDatesEqual} from "@nitra/nkmb-card-components/contexts/timeContextHelpers/AreDatesEqual";
import {CalendarCardDay} from "@nitra/nkmb-card-components/contexts/timeContextHelpers/CalendarCardDay";
import React, {useEffect, useState} from "react";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

/**
 * Element containing a big overview calendar for a month, including the option to switch between months
 * The mobile version also allows selecting a date and seeing details about that match
 * @constructor
 */
export function BigCalendarMobile() {
	const theme = useTheme()

	const {
		monthNameShort,
		weeks,
		selectedMatch,
		handleGoIntoPast,
		handleGoIntoFuture,
	} = useTimeManagementContext()

	const {isIos} = useIosContext()

	return (
		<Stack sx={{
			backgroundColor: theme.palette.background.default,
		}}>
			<Stack direction={"row"} alignItems={"center"} justifyContent={"flex-start"} sx={{px: 2, pt: 3}}>
				<Typography variant={"h4"} sx={{
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500, // Adjust font weight for iPhones
						},
					},
				}}>{monthNameShort}</Typography>
				<IconButton onClick={handleGoIntoPast}>
					<ChevronLeft style={{color: theme.palette.text.primary}}/>
				</IconButton>
				<IconButton onClick={handleGoIntoFuture}>
					<ChevronRight style={{color: theme.palette.text.primary}}/>
				</IconButton>
			</Stack>
			{weeks.map((week, index) =>
				<Box>
					<Stack key={index} direction={"row"} alignItems={"stretch"}
					       sx={{px: 2}}
					       justifyContent={"space-between"}>
						{week.map((day, index) =>
							<BigCalendarDayBoxMobile
								key={index}
								calendarDay={day}
							/>
						)}
					</Stack>
					<Divider variant={"fullWidth"}/>
				</Box>
			)}
			{selectedMatch &&
                <BigCalendarSelectedMatchInfoArea match={selectedMatch}/>
			}
		</Stack>
	)
}

function BigCalendarSelectedMatchInfoArea(props: {
	match: MatchListElementFirestoreDocument;
}) {
	const {
		date,
		competition,
		score,
		homeTeamName,
		awayTeamName,
		reportId,
		liveTickerKey,
		buyTicketLink,
	} = props.match;


	return (
		<Stack sx={{px: 2}}>
			<Stack direction={"row"} sx={{pt: 4, pb: 2}} spacing={1}>
				<Typography variant={"bodyS"}>
					{date.toLocaleString("sl-sl", {
						day: "2-digit",
						month: "2-digit",
						year: "numeric",
					})}
				</Typography>
				<Divider orientation={"vertical"} variant={"fullWidth"}/>
				<Typography variant={"bodyS"} sx={{opacity: 0.65}}>
					{getCompetitionNameFromType(competition)}
				</Typography>
			</Stack>
			<MatchVsContainer
				homeTeamName={homeTeamName}
				awayTeamName={awayTeamName}
				score={score}
				date={date}
			/>
			<MatchCallToActionButton
				reportId={reportId}
				liveTickerKey={liveTickerKey}
				buyTicketLink={buyTicketLink}
			/>
			{(reportId != undefined || liveTickerKey != undefined || buyTicketLink != undefined) &&
                <Box sx={{width: "100%", mt: 3}}/>}
		</Stack>

	)
}

export function BigCalendarDayBoxMobile(props: {
	calendarDay: CalendarCardDay;
}) {
	const {calendarDay} = props
	const {date} = calendarDay
	const theme = useTheme()

	const [isHomeMatch, setIsHomeMatch] = useState(false)
	const [isAwayMatch, setIsAwayMatch] = useState(false)

	const [isSelected, setIsSelected] = React.useState(false)
	const [borderColor, setBorderColor] = React.useState("transparent")

	const {matches, setSelectedDate, selectedDate, setSelectedMatch, month} = useTimeManagementContext();

	const [match, setMatch] = useState<MatchListElementFirestoreDocument | undefined>(undefined);

	useEffect(() => {
		const _match = matches.find(match => areDatesEqual(match.date, date));

		setMatch(_match);

		setIsHomeMatch(!!_match?.homeTeamName.toLowerCase().includes("maribor"))
		setIsAwayMatch(!!_match?.awayTeamName.toLowerCase().includes("maribor"))
	}, [date, matches])

	useEffect(() => {
		if (selectedDate) {
			setIsSelected(areDatesEqual(date, selectedDate))
		} else {
			setIsSelected(false)
		}
	}, [selectedDate])

	useEffect(() => {
		if (isHomeMatch) {
			setBorderColor(theme.palette.primary.main)
		} else if (isAwayMatch) {
			setBorderColor(theme.palette.secondary.main)
		} else {
			setBorderColor("transparent")
		}
	}, [isHomeMatch, isAwayMatch])

	const handleClick = () => {
		if (isSelected) {
			setSelectedDate(undefined)
			setSelectedMatch(undefined)
		} else {
			setSelectedDate(date)
			setSelectedMatch(match)
		}
	}

	return (
		<Box
			onClick={handleClick}
			sx={{
				border: "2px solid",
				borderColor: borderColor,
				height: "40px",
				width: "40px",
				borderRadius: "50%",
				my: "4px",
				backgroundColor: isSelected ? borderColor : "unset",
				color: isSelected && isHomeMatch ? theme.palette.primary.contrastText : "unset",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<Typography variant={"bodyM"}
			            sx={{opacity: calendarDay.date.getMonth() === month ? 1.0 : 0.35}}>{calendarDay.date.getDate()}</Typography>
		</Box>
	)
}
