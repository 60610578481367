import {alpha, Grid, Stack, SxProps, Typography, useTheme} from "@mui/material";
import {CardWithShadow, useIsMobileContext} from "@nitra/nkmb-card-components";
import {
	EmblemMaribor,
	ENationality,
	NkmbIconDolzina,
	NkmbIconDres,
	NkmbIconGol,
	NkmbIconKlubiPrej,
	NkmbIconPogodba,
	NkmbIconRd,
	NkmbIconStarost,
	NkmbIconTeza,
	NkmbIconVstop
} from "@nitra/nkmb-theme";
import {nationalityToFlagMap, nationalityToSloveneShorthandMap} from "@nitra/nkmb-theme/flags/ENationality";
import {EPlayerPosition, EPlayerPositionToPretty, PlayerProfileFirebaseDocument} from "models";
import React, {SVGProps} from "react";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function PlayerProfileStatCardContainer(props: {
	data: PlayerProfileFirebaseDocument
}) {
	return (
		<Grid container spacing={2}>
			<PlayerPositionCard value={props.data.playerPosition}/>
			<PlayerNationalityCard value={props.data.nationality}/>
			<PlayerBirthPlaceAndDateCard location={props.data.locationOfBirth} date={props.data.dateOfBirth}/>
			<PlayerAgeCard value={props.data.age}/>
			<PlayerHeightCard value={props.data.height}/>
			<PlayerWeightCard value={props.data.weight}/>
			{props.data.previousClub && <PlayerPreviousClubsCard value={props.data.previousClub}/>}
			{props.data.contractUntil &&
                <PlayerContractUntilCard value={props.data.contractUntil.toLocaleDateString(["sl-sl"], {
					year: "numeric",
					month: "numeric",
					day: "numeric",
				})}
                />}
			<PlayerAppearancesCard value={props.data.appearances}/>
			{props.data.playerPosition !== EPlayerPosition.GOALKEEPER && <PlayerGoalsCard value={props.data.goals}/>}
		</Grid>
	)
}

export function PlayerProfileStatCard(props: {
	title: string,
	value: string,
	icon: (props: SVGProps<SVGSVGElement>) => any,
	sx?: SxProps
}) {
	const {title, value, icon} = props

	const theme = useTheme()
	const isMobile = useIsMobileContext()
	const {isIos} = useIosContext()

	if (isMobile) {
		return (
			<Grid item container sx={{
				flexDirection: "row",
				alignItems: "center",
				borderBottom: "1px solid",
				ml: 2,
				borderColor: alpha(theme.palette.text.primary, 0.1),
				...props.sx,
			}} xs={12}>
				<Grid item xs={2}>
					{
						icon({
							height: "42px",
							width: "42px",
							opacity: (title === "rumeni kartoni" || title === "rdeči kartoni") ? 1.0 : 0.3,
							color: title === "rumeni kartoni" ? theme.palette.secondary.main : title === "rdeči kartoni" ? "#C20831" : "unset",
						})}
				</Grid>
				<Grid item xs={4}>
					<Typography
						sx={{
							color: "#676767"
						}}
						variant={"bodyM"}
					>{title}</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography
						sx={{
							color: theme.palette.primary.main,
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}
						variant={"h5"}
					>{value}</Typography>
				</Grid>
			</Grid>
		)
	} else {
		return (
			<Grid item>
				<CardWithShadow
					disableOnHoverActions={true}
					cardHeightOverride={104}
					sx={{
						pr: 2,
					}}
				>
					<Stack direction={"row"} alignItems={"center"}>
						{
							icon({
								height: "100px",
								width: "100px",
								opacity: 0.2,
							})}
						<Stack direction={"column"} sx={{height: "100px"}} justifyContent={"center"}>
							<Typography
								sx={{
									color: "#676767"
								}}
								variant={"bodyL"}
							>{title}</Typography>
							<Typography
								sx={{
									pt: 1,
									color: theme.palette.primary.main
								}}
								variant={"h4"}
							>{value}</Typography>
						</Stack>
					</Stack>

				</CardWithShadow>
			</Grid>
		)
	}
}

export function PlayerPositionCard(props: {
	value: EPlayerPosition,
}) {
	return (
		<PlayerProfileStatCard
			value={EPlayerPositionToPretty(props.value)}
			icon={NkmbIconVstop}
			title={"igralni položaj"}
		/>
	)
}

function PlayerNationalityCard(props: {
	value: ENationality,
}) {
	return (
		<PlayerProfileStatCard
			title={"državljanstvo"}
			value={nationalityToSloveneShorthandMap[props.value] ?? ""}
			icon={nationalityToFlagMap[props.value] ?? EmblemMaribor}
		/>
	)
}

export function PlayerBirthPlaceAndDateCard(props: {
	location: string,
	date: Date
}) {
	const {location, date} = props;
	// TODO this needs to be change a big and processed into a nicer format
	return (
		<PlayerProfileStatCard
			title={"datum rojstva"}
			value={(location ? `${location}, ` : "") + date.toLocaleDateString(["sl-sl"], {
				year: "numeric",
				month: "numeric",
				day: "numeric",
			})
			}
			icon={NkmbIconRd}
		/>
	)
}

export function PlayerAgeCard(props: {
	value: number
}) {
	return (
		<PlayerProfileStatCard
			title={"starost"}
			value={props.value.toString()}
			icon={NkmbIconStarost}
		/>
	)
}

export function PlayerHeightCard(props: {
	value: number
}) {
	return (
		<PlayerProfileStatCard
			title={"višina"}
			value={props.value + " cm"}
			icon={NkmbIconDolzina}
		/>
	)
}

function PlayerWeightCard(props: {
	value: number
}) {
	return (
		<PlayerProfileStatCard
			title={"teža"}
			value={props.value + " kg"}
			icon={NkmbIconTeza}
		/>
	)
}

function PlayerPreviousClubsCard(props: {
	value: string
}) {
	return (
		<PlayerProfileStatCard
			title={"prejšnji klubi"}
			value={props.value}
			icon={NkmbIconKlubiPrej}
		/>
	)
}

function PlayerContractUntilCard(props: {
	value: string
}) {
	return (
		<PlayerProfileStatCard
			title={"pogodba do"}
			value={props.value}
			icon={NkmbIconPogodba}
		/>
	)
}

export function PlayerAppearancesCard(props: {
	value: number
}) {
	return (
		<PlayerProfileStatCard
			title={"nastopi"}
			value={props.value.toString()}
			icon={NkmbIconDres}
		/>
	)
}

export function PlayerGoalsCard(props: {
	value: number
}) {
	return (
		<PlayerProfileStatCard
			title={"goli"}
			value={props.value.toString()}
			icon={NkmbIconGol}
		/>
	)
}
