import {alpha, CardContent, Stack, Typography} from "@mui/material";
import {CardOverImageTitle, CardWithShadow, useIsMobileContext} from "@nitra/nkmb-card-components";
import {TicketFirestoreDocument} from "models";
import {ETicketLevel} from "models/enums/ETicketLevel";
import React, {useEffect, useState} from "react";
import theme, {NkmbIconExternalLink} from "@nitra/nkmb-theme";

export function MatchTicketCard(props: {
	data: TicketFirestoreDocument,
	extraText: string,
}) {
	const {data, extraText} = props;
	const {ticketLevel, actionLink} = data
	const isMobile = useIsMobileContext();
	const [imageUrl, setImageUrl] = useState("")

	useEffect(() => {
		switch (ticketLevel) {
			case ETicketLevel.NORMAL:
				if (isMobile) {
					setImageUrl("/images/tickets_mobile_day_ticket.jpg")
				} else {
					setImageUrl("/images/vstopnice-sedezi.png")
				}
				break;
			case ETicketLevel.VPS:
				if (isMobile) {
					setImageUrl("/images/tickets_mobile_day_vps_ticket.png")
				} else {
					setImageUrl("/images/sezonska-poslovni-sedezi.png")
				}
				break;
			case ETicketLevel.SKYBOX:
				setImageUrl("/images/sezonska-privatna-loza.png")
				break;
		}
	}, [ticketLevel]);

	return (
		<CardWithShadow
			sx={{
				display: "flex",
				flexDirection: "column",
				position: "relative",
			}}
			cardHeightOverride={isMobile ? 141 : 360}
			fixedWidth={isMobile ? undefined : 288}
			fullSizeImageUrl={imageUrl}
			onClickNavigateUrl={actionLink}
		>
			<CardContent sx={{mt: "auto", zIndex: 1}} style={{paddingBottom: 16}}>
				<Stack>
					<CardOverImageTitle
						title={ticketLevel === ETicketLevel.NORMAL ? "Vstopnica" : ticketLevel === ETicketLevel.VPS ? "Hospitality vstopnica" : "Zasebna loža"}
						variant={"podnaslovM"}
						sx={{
							mb: isMobile ? -1 : 0,
						}}
					/>
					<Typography
						sx={{
							color: alpha(theme.palette.background.default, 0.65),
							pt: 2,
						}}
						variant={"bodyS"}>{extraText}</Typography>
					<NkmbIconExternalLink
						style={{
							position: "absolute",
							right: 24,
							bottom: 24,
							height: 24,
							width: 24,
							color: theme.palette.background.default
						}}
					/>
				</Stack>
			</CardContent>
		</CardWithShadow>
	)
}
