import {CompetitionPlayerStatistics} from "./CompetitionPlayerStatistics";
import {CompetitionClubStatistics} from "./CompetitionClubStatistics";
import React from "react";
import {ButtonTabNavigatorWithRouter} from "@nitra/nkmb-card-components";
import {
	CompetitionFilterButtonsContextProvider
} from "../../../components/CompetitionFilterButtons/CompetitionFilterButtonsContext";
import {CompetitionStatisticsContextProvider} from "../common/CompetitionStatisticsContext";

const tabs = [{
	path: "club", label: "Klub", component: <CompetitionClubStatistics/>
}, {
	path: "players", label: "Igralci", component: <CompetitionPlayerStatistics/>
}]

export function CompetitionStatistics() {
	return (
		<CompetitionFilterButtonsContextProvider allowMultipleSelection={false}>
			<CompetitionStatisticsContextProvider>
				<ButtonTabNavigatorWithRouter tabs={tabs} sx={{px: 0, pt: 2}} blackVersion={true}/>
			</CompetitionStatisticsContextProvider>
		</CompetitionFilterButtonsContextProvider>
	)
}